import axios from 'axios';
import config from '../../../../../config/environmentConfig';
import { SHIPMENT_ACTION_LIST, COMMON_ACTIONS, CONSTANTS } from '../../../../../static/constants';
import { showToasterMessage } from '../../../../../utils';

const { lang } = require('../../../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');



export const getShiprocketIntegratedChannel = (payload) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LIST_LOADER, data: true });
    
    const headers = {
      "relationshipId": payload.relationshipId,
      "Content-Type": "application/json"
    };

    return axios.get(`${config.API_BASE_URL}/${config.rootContextShiprocket}/${config.API_VERSION_SHIPROCKET}/channels/getChannels`, {headers})
      .then(response => {
        if(response.data?.status !== 200){
          throw new Error(`${response.data?.data?.message || 'Some Error Occured'}`);
        }
        dispatch({ type: SHIPMENT_ACTION_LIST.SHIPROCKET_CHANNELS, data: response.data?.data?.data || [] });
      })
      .catch(err => {
        showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
      })
      .finally(()=>{
        dispatch({ type: COMMON_ACTIONS.HIDE_LIST_LOADER, data: false });
      })
  }
}

export const getShiprocketAccountAddress = (payload) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LIST_LOADER, data: true });
    
    const headers = {
      "relationshipId": payload.relationshipId,
      "Content-Type": "application/json"
    };

    return axios.get(`${config.API_BASE_URL}/${config.rootContextShiprocket}/${config.API_VERSION_SHIPROCKET}/settings/getAccountAddress`, {headers})
      .then(response => {
        if(response.data?.status !== 200){
          throw new Error(`${response.data?.data?.message || 'Some Error Occured'}`);
        }
        dispatch({ type: SHIPMENT_ACTION_LIST.SHIPROCKET_ADDRESS, data: response.data?.data?.data?.shipping_address || [] });
      })
      .catch(err => {
        showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
      })
      .finally(()=>{
        dispatch({ type: COMMON_ACTIONS.HIDE_LIST_LOADER, data: false });
      })
  }
}

export const createReturnShipmentOrder = (payload, props, afterApiResponseCall) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_SAVE_LOADER });
    const headers = {
      "relationshipId": (props.companyInfo || {}).relationshipId,
      "Content-Type": "application/json"
    };

    return axios.post(`${config.API_BASE_URL}/${config.rootContextShiprocket}/${config.API_VERSION_SHIPROCKET}/return/createReturnOrder`, payload, {headers})
      .then(res => {
        if(res.data?.status !== 200){
          props.updateState({stepError: res.data?.data?.message})
          throw new Error(`${res.data?.data?.message || 'Some Error Occured'}`);
        }
        if(res?.data?.data?.status === 'RETURN PENDING'){
          const shipmentPayload = {
            ...(props.boughtShipment || {}),
            purchasedShipmnetEasyPostId: props.boughtShipment?.purchasedShipmnetEasyPostId || 0,
            relationshipId: props.boughtShipment?.relationshipId || props.companyInfo?.relationshipId,
            customerId: props.companyInfo?.customerId || props.boughtShipment?.customerId || props.deliveyNotesData.customerId,
            shipmentMasterId: props.boughtShipment?.shipmentMasterId || props.deliveyNotesData?.shipmentMasterId || 0,
            shipmentId: res.data?.data?.shipment_id || '',
            version: props.boughtShipment?.version || 0,
            shiprocketOrderId: res.data?.data?.order_id || '',
            shiprocketShipmentStatus: res.data?.data?.status,
            isReturn: 1
          };

          props.updateShipmentDetails(shipmentPayload);
            
        }
        props.updateState({current: props.current+1, stepError: undefined});

        let data = res.data.data
        dispatch({ type: SHIPMENT_ACTION_LIST.SHIPROCKET_ORDER_DETAILS, data });

        afterApiResponseCall && afterApiResponseCall(data.order_id);
      })
      .catch(err => {
        showToasterMessage({ messageType: 'error', description: lang[((err.res || {}).data || {}).message] || 'Some error occurred' })
      })
      .finally(()=>{
        dispatch({ type: COMMON_ACTIONS.HIDE_SAVE_LOADER });
      })
  }
}

export const getCarrierPricesShiprocket = (payload) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LIST_LOADER, data: true });
    
    const headers = {
      "relationshipId": payload.relationshipId,
      "Content-Type": "application/json"
    };

    return axios.post(`${config.API_BASE_URL}/${config.rootContextShiprocket}/${config.API_VERSION_SHIPROCKET}/shipment/getRates`, payload, {headers})
      .then(response => {
        if(response.data?.status !== 200){
          throw new Error(`${response?.data?.data?.message || 'Some Error Occured'}`);
        }
        dispatch({ type: SHIPMENT_ACTION_LIST.SHIPROCKET_CARRIER_DETAILS, data: response.data?.data || {} });
      })
      .catch(err => {
        showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
      })
      .finally(()=>{
        dispatch({ type: COMMON_ACTIONS.HIDE_LIST_LOADER, data: false });
      })
  }
}


export const generateAwbForShipment = (payload, props) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_SAVE_LOADER });
    
    const headers = {
      "relationshipId": (props.companyInfo || {}).relationshipId || "73",
      "Content-Type": "application/json"
    };

    return axios.post(`${config.API_BASE_URL}/${config.rootContextShiprocket}/${config.API_VERSION_SHIPROCKET}/couriers/generateAwbForShipment`, payload, {headers})
      .then(res => {
        if(res.data?.status !== 200){
          throw new Error(`${res.data?.data?.message || 'Some Error Occured'}`);
        }
        props.updateState({shiprocketAwb: res.data, current: props.current + 1, stepError: undefined});

        if(res?.data?.data?.awb_assign_status === 1 && res?.data?.data?.response?.data?.awb_code){
          const shipmentPayload = {
            ...(props.boughtShipment || {}),
            purchasedShipmnetEasyPostId: props.boughtShipment?.purchasedShipmnetEasyPostId || 0,
            relationshipId: props.boughtShipment?.relationshipId || props.companyInfo?.relationshipId,
            customerId: props.companyInfo?.customerId || props.boughtShipment?.customerId || props.deliveyNotesData.customerId,
            shiprocketAwbCode: props.boughtShipment?.awbCode || res?.data?.data?.response?.data?.awb_code || '',
            carrier: res?.data?.data?.response?.data?.courier_name
          };

          props.updateShipmentDetails(shipmentPayload);
        }
        props.scheduleCourierPickup(payload, props);
        
      })
      .catch(err => {
        props.updateState({stepError: err.message})
        showToasterMessage({ messageType: 'error', description: lang[((err.res || {}).data || {}).message] || 'Some error occurred' })
      })
      .finally(()=>{
        dispatch({ type: COMMON_ACTIONS.HIDE_SAVE_LOADER });
      })
  }
}

export const scheduleCourierPickup = (payload, props) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_SAVE_LOADER });
    
    const headers = {
      "relationshipId": (props.companyInfo || {}).relationshipId,
      "Content-Type": "application/json"
    };

    return axios.post(`${config.API_BASE_URL}/${config.rootContextShiprocket}/${config.API_VERSION_SHIPROCKET}/couriers/schedulePickupDate`, payload, {headers})
      .then(res => {
        props.updateState({ShippingPickupInfo: res.data?.data, current: props.current + 1, stepError: undefined});
        props.generateManifest(payload, props);
        props.generateShippingLabel(payload, props);
      })
      .catch(err => {
        showToasterMessage({ messageType: 'error', description: lang[((err.res || {}).data || {}).message] || 'Some error occurred' })
      })
      .finally(()=>{
        dispatch({ type: COMMON_ACTIONS.HIDE_SAVE_LOADER });
      })
  }
}

export const generateManifest = (payload, props) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_SAVE_LOADER });
    
    const headers = {
      "relationshipId": (props.companyInfo || {}).relationshipId,
      "Content-Type": "application/json"
    };
    const payload2 = {
      shipment_id:[payload.shipment_id]
    }

    return axios.post(`${config.API_BASE_URL}/${config.rootContextShiprocket}/${config.API_VERSION_SHIPROCKET}/shipment/generateManifest`, payload2, {headers})
      .then(res => {
        props.updateState({shippingManifestData: res.data?.data, current: props.current + 1, stepError: undefined})
      })
      .catch(err => {
        props.updateState({stepError: err.message})
        showToasterMessage({ messageType: 'error', description: lang[((err.res || {}).data || {}).message] || 'Some error occurred' })
      })
      .finally(()=>{
        dispatch({ type: COMMON_ACTIONS.HIDE_SAVE_LOADER });
      })
  }
}

export const generateShippingLabel = (payload, props) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_SAVE_LOADER });
    
    const headers = {
      "relationshipId": (props.companyInfo || {}).relationshipId,
      "Content-Type": "application/json"
    };
    const payload2 = {
      shipment_id:[payload.shipment_id]
    }

    return axios.post(`${config.API_BASE_URL}/${config.rootContextShiprocket}/${config.API_VERSION_SHIPROCKET}/shipment/generateLabel`, payload2, {headers})
      .then(res => {
        if(res.data?.status !== 200){
          throw new Error(`${res.data?.data?.message || 'Some Error Occured'}`);
        }
        if(res.data?.data?.label_created){
          const shipmentPayload = {
            ...(props.boughtShipment || {}),
            purchasedShipmnetEasyPostId: props.boughtShipment?.purchasedShipmnetEasyPostId || 0,
            relationshipId: props.boughtShipment?.relationshipId || props.companyInfo?.relationshipId,
            customerId: props.companyInfo?.customerId || props.boughtShipment?.customerId || props.deliveyNotesData.customerId,
            labelUrl: props.boughtShipment?.labelUrl || res?.data?.data?.label_url || '',
            isUpdate: 1
          };

          props.updateShipmentDetails(shipmentPayload);
        }
        props.updateState({shippingLabelData: res.data?.data, stepError: undefined})
      })
      .catch(err => {
        props.updateState({stepError: err.message})
        showToasterMessage({ messageType: 'error', description: lang[((err.res || {}).data || {}).message] || 'Some error occurred' })
      })
      .finally(()=>{
        dispatch({ type: COMMON_ACTIONS.HIDE_SAVE_LOADER });
      })
  }
}

export const updateShipmentDetails = (payload) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_SAVE_LOADER });

    return axios.post(`${config.API_BASE_URL}/${config.rootContextEasyPost}/epCarrier/updateShipmentInfo`, payload)
      .then(res => {
        if(res.data){
          dispatch({ type: SHIPMENT_ACTION_LIST.BOUGHT_SHIPMENT, data: res.data });
        }
      })
      .catch(err => {
        showToasterMessage({ messageType: 'error', description: 'Some error occurred' })
      })
      .finally(()=>{
        dispatch({ type: COMMON_ACTIONS.HIDE_SAVE_LOADER });
      })
  }
}

export const getTrackingByOrderId = (payload) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LIST_LOADER, data: true });
    
    const headers = {
      "relationshipId": payload.relationshipId,
      "Content-Type": "application/json"
    };

    return axios.get(`${config.API_BASE_URL}/${config.rootContextShiprocket}/${config.API_VERSION_SHIPROCKET}/shipment/getTrackingByOrderId`, {params : {orderId: payload.orderId}, headers: headers})
      .then(response => {
        if(response.data?.status !== 200){
          throw new Error(`${response?.data?.data?.message || 'Some Error Occured'}`);
        }
        dispatch({ type: SHIPMENT_ACTION_LIST.SHIPMENT_TRACKING_DETAILS, data: response.data?.data[0][payload.orderId]?.tracking_data || {} });
      })
      .catch(err => {
        showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
      })
      .finally(()=>{
        dispatch({ type: COMMON_ACTIONS.HIDE_LIST_LOADER, data: false });
      })
  }
}

export const generateAwbForReturnShipment = (payload, props) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_SAVE_LOADER });
    
    const headers = {
      "relationshipId": (props.companyInfo || {}).relationshipId || "73",
      "Content-Type": "application/json"
    };

    return axios.post(`${config.API_BASE_URL}/${config.rootContextShiprocket}/${config.API_VERSION_SHIPROCKET}/couriers/generateAwbForShipment`, payload, {headers})
      .then(res => {
        if(res.data?.status !== 200){
          throw new Error(`${res.data?.data?.message || 'Some Error Occured'}`);
        }
        props.updateState({shiprocketAwb: res.data, current: props.current + 1, stepError: undefined});

        if(res?.data?.data?.awb_assign_status === 1 && res?.data?.data?.response?.data?.awb_code){
          const shipmentPayload = {
            ...(props.boughtShipment || {}),
            purchasedShipmnetEasyPostId: props.boughtShipment?.purchasedShipmnetEasyPostId || 0,
            relationshipId: props.boughtShipment?.relationshipId || props.companyInfo?.relationshipId,
            customerId: props.companyInfo?.customerId || props.boughtShipment?.customerId || props.deliveyNotesData.customerId,
            shiprocketAwbCode: props.boughtShipment?.awbCode || res?.data?.data?.response?.data?.awb_code || '',
            carrier: res?.data?.data?.response?.data?.courier_name
          };

          props.updateShipmentDetails(shipmentPayload);
        }
      })
      .catch(err => {
        props.updateState({stepError: err.message})
        showToasterMessage({ messageType: 'error', description: lang[((err.res || {}).data || {}).message] || 'Some error occurred' })
      })
      .finally(()=>{
        dispatch({ type: COMMON_ACTIONS.HIDE_SAVE_LOADER });
      })
  }
}