import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { pushModalToStack,  } from '../../../../actions/commonActions';
import { downloadFileFromBucket } from '../../../../actions/downloadFileAction.js';
import { deleteUnboxingVideo , createUnboxingVideo} from './action.js';
import { fetchPublishAndUnpublishList } from '../../../product/RatingAndReview/action';
import { fetchSODetail } from '../../../modal/modalBody/customer/SODetail/action.js';
import UnBoxingDrawer from '../../../../components/drawer/inventory/UnboxingDrawer.js';


class UnBoxingDrawerDrawerContainer extends Component {
  constructor(props) {
    super(props);
 

    
    this.state = {
      pageNumber: 1,
      pageSize: 100,
      selectedItems: [],
      videoLink:props?.data?.url,
      showOnHomepage: props?.data?.showOnMainPage ? true:false,
      // ...(this.props.feedbackData || {})

    };
  }

  componentWillReceiveProps(props) {
  
  }

  componentDidMount() {
    const companyInfo = this.props.companyInfo || {};
        const payload = { relationshipId: companyInfo.relationshipId, customerId: this.props.data.customerId, salesOrderMasterId: this.props.data.salesOrderMasterId, };
        console.log(this.props ,"sjhdsgdh")
        this.props.fetchSODetail(payload);
        if(this.props.data?.itemList?.length){
          const itemList = (this.props.data?.itemList || []).map((item) => ({
            ...item,
            itemName: item?.inventoryItemName,
          }))||[];
          this.setState({
            selectedItems:itemList,
          })
        }
  }

  render() {
    return (
      <>
       <UnBoxingDrawer {...this.props}  {...this.state}  updateDrawerState={(data) => { this.setState(data) }} />
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    feedbackData:state.product.feedbackData,
    soDetail: state.so.soDetail,

  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchPublishAndUnpublishList,
   fetchSODetail,
    pushModalToStack,
    deleteUnboxingVideo,
    createUnboxingVideo,
    downloadFileFromBucket
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(UnBoxingDrawerDrawerContainer);
