import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as filter from "lodash.filter";
import * as find from "lodash.find";
import cloneDeep from 'lodash/cloneDeep';
import {
  CONSTANTS,
  CONSTANTS_TRANSACTIONS,
  CONSTANTS_TRANSACTIONS_KEY,
} from "../../../../static/constants";
import ShipmentComponent from "../../../../components/customer/salesOrder/shipment";

import {
  fetchSoDataForShipment,
  fetchAllCarriers,
  addCarrierName,
  deleteCarrierName,
  fetchAllFreightType,
  addFreightType,
  deleteFreightType,
  createShipment,
  getShipmentByShipmentId,
  resetShipmentData,
  resetCustomerDetail,resetSoUnShiped,fetchSoDataForShipmentData,resetDataTry,
  getNextTxNumber,
} from "./action";
import { fetchCustomers } from "../../Listing/action";
import {
  showModal,
  hideModal,
  pushModalToStack,
  popModalFromStack,
  fetchCustomerDetailsByCustomerId,
  deleteAddress,
  deleteContact,
  fetchDocuments,
  addDocumentName,
  deleteDocumentName,
  fetchUserPreferences,
  saveUserPrefrences,
  fetchCountries,
  fetchAddresses,
  resetAddresses,
  getRelationshipAddress,
} from "../../../../actions/commonActions";
import {
  fetchDataIfNeeded,
  fetchPaginationDataIfNeeded,
} from "../../../../utils";
import {
  resetSODetails,
  fetchSODetail,
} from "../../../modal/modalBody/customer/SODetail/action";
import ShipmentDetail from "../../../modal/modalBody/customer/ShipmentDetail";
import QualityCheckDrawer from "../../../../containers/drawer/qualityCheck/index";
import { fetchAllContacts } from "../../Listing/ShowConact/action";
import { fetchAllShipment } from "../../shipment/Listing/action";
import SettingSalesDrawer from "../../../drawer/customer/settingSalesDrawer";
import { resetAmendSoDataForInvoice } from "../../invoice/StandardSalesInvoice/action";

class ShipmentContainer extends Component {
  constructor(props) {
    super(props);
    const relationshipBillingAddresses =
      filter(props.companyInfo.boLocationList, {
        locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS,
      }) || [];
    const relationshipBillingAddress =
      find(props.companyInfo.boLocationList, { isDefault: 1 }) || {};
    const linkProps = (this.props.location && this.props.location.state) || {};
    this.state = {
      displayName:"drawer.settingDrawer.headingNameShipment",
      viewName:"Shipment",
      tabDocumentOptions: {txType: CONSTANTS_TRANSACTIONS.SHIPMENT, label: 'Shipment', numberExample: 'SHP-1', numberInfo: 'CM-CustomerNumber-DDMMYY' },
      docName: "Sales Shipment",

      selectedPackages: props.packageNumber ? [props.packageNumber] : [],
      shipmentDate: new Date(),
      pageNumber: 1,
      pageSize: 100,
      isToShowAllPackage: props.type === "new" || props.type === "connect" ? false : true,
      type: props.type,
      expectedDate: new Date(),
      relationshipBillingAddresses,
      relationshipBillingAddress,
      customerId: props.customerId || linkProps.customerId,
      soMasterId: props.soMasterId,
      amendSalesOrderMasterId: props.amendSalesOrderMasterId,
      shipmentMasterId: props.shipmentMasterId,
      isStockUpdated: 1,
      txPreferences: { txType: "shipment", templateName: "standard" },
      txColumns: [],
      txColumnSetting: {},
      txMandatoryColumns: [
        "itemName",
        "description",
        "quantity",
        "uomId",
        "sno",
      ],
      txAvailableColumn: {
        itemName: true,
        quantity: true,
        description: true,
        uom: true,
        stockNumber: true,
        partNumber: true,
        warehouseName: true,
        origin: true,
        hsCode: true,
        materialNumber: true,
        packageNumber: true,
        salesOrderNumber: true,
      },
      dimensionUnit: "inch",
      length: 0,
      width: 0,
      height: 0,
      weight: 0,
      weightUnit: "ounces"

    };
  }

  componentDidMount() {   
    const companyInfo = this.props.companyInfo || {};
    const linkProps = (this.props.location && this.props.location.state) || {};
    const payload = {
      customerId: this.props.customerId || linkProps.customerId,
      soMasterId: this.props.soMasterId || linkProps.soMasterId,
      amendSalesOrderMasterId: this.props.amendSalesOrderMasterId || linkProps.amendSalesOrderMasterId,
      salesOrderMasterId: this.props.soMasterId || linkProps.soMasterId,
      shipmentMasterId:
        this.props.shipmentMasterId || linkProps.shipmentMasterId,
      relationshipId: companyInfo.relationshipId,
      userId: (this.props.userInfo || {}).userId,
      packageMasterIdArr: this.props.update ? this.props.packageMasterIds : 0,
      pageNumber: 1,
      pageSize: 100,
      type: this.props.type,
      isCompact:true,
      // callBack:(data)=>{this.setState({dataForUnShipped:cloneDeep(data)})}
    };
    this.props.resetSoUnShiped();
    this.props.resetAmendSoDataForInvoice();
    this.props.resetCustomerDetail();
    this.props.resetDataTry();
    payload.type !== "new" &&
      fetchDataIfNeeded(
        "fetchSoDataForShipment",
        "soUnShippedList",
        this.props,
        payload
      );
    this.props.fetchSoDataForShipmentData(payload);
    payload.type !== "new" &&
      fetchDataIfNeeded(
        "fetchCustomerDetailsByCustomerId",
        "customerDetail",
        this.props,
        payload
      );
    fetchDataIfNeeded("fetchAllCarriers", "freightList", this.props, payload);
    fetchDataIfNeeded(
      "fetchAllFreightType",
      "carrierList",
      this.props,
      payload
    );
    this.props.resetShipmentData();
    this.props.resetSODetails();
    // payload.type !== 'new' && this.props.fetchSODetail(payload);
    payload.shipmentMasterId &&
      fetchDataIfNeeded(
        "getShipmentByShipmentId",
        "shipmentData",
        this.props,
        payload
      );
    fetchDataIfNeeded("fetchDocuments", "documentList", this.props, payload);
    fetchDataIfNeeded("fetchCountries", "countries", this.props, payload);
    fetchDataIfNeeded(
      "fetchUserPreferences",
      "allPreferences",
      this.props,
      payload
    );
    fetchPaginationDataIfNeeded(
      "fetchCustomers",
      "customers",
      this.props,
      { ...payload, isCompact: true },
      true
    );
    if (!this.props.update) {
      fetchDataIfNeeded("getNextTxNumber", "nextTxNumber", this.props, payload);
    }
  }

  componentWillReceiveProps(props) {
    if(props.soPackageDetail && Object.keys(props.soPackageDetail)?.length){
      const billingAddress = find(props.soPackageDetail.boLocationPackageList, { locationType: CONSTANTS.BILLING_ADDRESS}) || {};
      const shippingAddress = find(props.soPackageDetail.boLocationPackageList, { locationType: CONSTANTS.SHIPPING_ADDRESS}) || {};
      const relationshipBillingAddress = find(props.soPackageDetail.boLocationPackageList, { locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS }) || {};
      let {soPackageDetail ={}} = props;
      const selectedContact = props.soPackageDetail.additionalInfoList[0];

      this.setState({
        selectedContact,
        length: soPackageDetail.length,
        height: soPackageDetail.height,
        width: soPackageDetail.width,
        dimensionUnit: soPackageDetail.dimensionUnit || "inch",
        weight:soPackageDetail.weight,
        weightUnit: soPackageDetail.weightUnit || "ounces",
        shippingAddress:shippingAddress,
        billingAddress,   
        relationshipBillingAddress,
        shipmentDetailList: soPackageDetail.customerSOShipmentDetailsList,
        referenceNumber:soPackageDetail.referenceNumber,
      })
    }
    if (
      props.documentList?.length &&
      !this.state.docName
    ) {
      props.documentList.forEach((document) => {
        if (
          document.txType ===
            CONSTANTS_TRANSACTIONS_KEY[CONSTANTS_TRANSACTIONS.SHIPMENT] &&
          document.isDefault
        ) {
          this.setState({
            documentNameId: document.documentNameId,
            docName: document.docName,
          });
        }
      });
    }

    if (props.boRelationshipAddress && props.boRelationshipAddress.length) {
      const allRelationshipBillingAddress = [];
      props.boRelationshipAddress.forEach((e) => {
        if (e.locationType === "RelationshipBillingAddress") {
          allRelationshipBillingAddress.push(e);
        }
      });
      this.setState({
        allRelationshipBillingAddress,
      });
    }

    if (
      props.addresses &&
      ((props.addresses.BillingAddress || []).length ||
        (props.addresses.ShippingAddress || []).length)
    ) {
      if (
        props.addresses.BillingAddress &&
        props.addresses.BillingAddress.length
      ) {
        let defaultBillingAddress = {};
        if (!(this.props.billingAddress || {}).streetAddress1) {
          defaultBillingAddress =
            find(props.addresses.BillingAddress, { isDefault: 1 }) || {};
        }
        this.setState({
          allBillingAddress: props.addresses.BillingAddress,
          billingAddress: defaultBillingAddress,
        });
      }
      if (
        props.addresses.ShippingAddress &&
        props.addresses.ShippingAddress.length
      ) {
        let defaultShippingAddress = {};
        if (!(this.props.shippingAddress || {}).streetAddress1) {
          defaultShippingAddress =
            find(props.addresses.ShippingAddress, { isDefault: 1 }) || {};
        }
        this.setState({
          allShippingAddress: props.addresses.ShippingAddress,
          shippingAddress: defaultShippingAddress,
        });
      }
      setTimeout(() => {
        props.resetAddresses();
      }, 1000);
    }

    if (props.customerDetail && props.customerDetail.customerId) {
      const customer = props.customerDetail || {};
      const allShippingAddress =
        (customer &&
          filter(customer.boLocationList, {
            locationType: CONSTANTS.SHIPPING_ADDRESS,
          })) ||
        [];
      const shippingAddress = find(allShippingAddress, { isDefault: 1 }) || {};
      const allBillingAddress =
        (customer &&
          filter(customer.boLocationList, {
            locationType: CONSTANTS.BILLING_ADDRESS,
          })) ||
        [];
      const billingAddress = find(allBillingAddress, { isDefault: 1 }) || {};
      const selectedContact =
        (customer.boContactList || []).find((x) => x.isPrimaryContact) || {};
      this.setState({
        customerDetail: customer,
        customerId: customer.customerId,
        selectedPackages: this.state.selectedPackages || [],
        billingAddress,
        shippingAddress,
        allShippingAddress,
        allBillingAddress,
        selectedContact,
      });
      props.resetCustomerDetail();
    }

    if (
      (props.soDetail || {}).salesOrderMasterId &&
      (props.customerDetail || {}).customerId
    ) {
      let shippingAddress =
        find(props.soDetail.boLocationSOList || [], {
          locationType: CONSTANTS.SHIPPING_ADDRESS,
        }) || {};
      let billingAddress =
        find(props.soDetail.boLocationSOList || [], {
          locationType: CONSTANTS.BILLING_ADDRESS,
        }) || {};
      let selectedContact = (props.soDetail.additionalInfoList || [])[0] || {};
      let relationshipBillingAddress =
        find(props.soDetail.boLocationSOList || [], {
          locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS,
        }) || {};
      // const linkProps = (this.props.location && this.props.location.state) || {}
      delete selectedContact.additionalInfoId;
      delete billingAddress.locationSOId;
      delete shippingAddress.locationSOId;
      delete relationshipBillingAddress.locationSOId;
      this.setState({
        selectedContact,
        billingAddress,
        shippingAddress,
        relationshipBillingAddress,
        soData: props.soDetail,
        selectedPackages: props.packageNumber ? [props.packageNumber] : [],
        remarksInternal: props.soDetail.remarksInternal,
        remarksCustomer: props.soDetail.remarksCustomer,
        
      });
      this.props.resetSODetails();
    }

    if (props.allPreferences && props.allPreferences.length) {
      let txPreferences =
        find(props.allPreferences, {
          txType: "shipment",
          templateName: "standard",
        }) || {};
      if (txPreferences) {
        let txColumns = txPreferences.gridPreference
          ? (JSON.parse(txPreferences.gridPreference) || {}).columnList || []
          : [];
        txColumns = txColumns.length
          ? txColumns
          : (this.state.txMandatoryColumns || []).concat(
              "product",
              "description"
            );
        const txColumnSetting = txPreferences.gridPreference
          ? (JSON.parse(txPreferences.gridPreference) || {}).columnSetting || {}
          : [];
        const isColumnFitTable =
          Object.keys(txColumnSetting).length ||
          this.state.isColumnFitTable === false
            ? false
            : true;
        let tablePreference = txPreferences.tablePreference;
        this.setState({
          tablePreference,
          isColumnFitTable,
          preferenceId: txPreferences.preferenceId,
          txColumns,
          txColumnSetting,
          txPreferences,
        });
      } else {
        this.setState({
          txColumns: (this.state.txMandatoryColumns || []).concat(
            "product",
            "description"
          ),
        });
      }
    }

    if (props.shipmentData && props.shipmentData.shipmentMasterId) {
      let selectedPackages = [];
      (props.shipmentData.customerSOShipmentDetailsList || []).map((ele) => {
        if (selectedPackages.indexOf(ele.packageNumber) < 0) {
          selectedPackages.push(ele.packageNumber);
        }
        return ele;
      });

      let {shipmentData} = props
      this.setState({
        selectedPackages: selectedPackages,
        version: shipmentData.version,
        trackingNumber: shipmentData.trackingNumber,
        shipmentMasterId: shipmentData.shipmentMasterId,
        selectedCarrier: shipmentData.carrierName,
        carrierId: shipmentData.carrierId,
        selectedFreighType: shipmentData.freightType,
        freightTypeId: shipmentData.freightTypeId,
        consigneeName: shipmentData.consigneeName,
        //soUnShippedList: shipmentData.customerSOShippedPackages
        shipmentDetailList: shipmentData.customerSOShipmentDetailsList,
        nextTxNumber: shipmentData.shipmentNumber,
        shipmentNumber: props.update ? shipmentData.shipmentNumber : null,
        remarksInternal: shipmentData.remarksInternal,
        remarksCustomer: shipmentData.remarksCustomer,
        length:shipmentData.length,
        width:shipmentData.width,
        height:shipmentData.height,
        dimensionUnit: shipmentData.dimensionUnit || "inch",
        weight:shipmentData.weight,
        weightUnit: shipmentData.weightUnit || "ounces",
      });

      setTimeout(() => {
        props.resetShipmentData();
      }, 1000);
    }

    if (props.customerContactList && props.customerContactList.length) {
      this.setState({
        boContactList: props.customerContactList,
      });
    }
  }

  openDeliveryNotesDetails = (data) => {
    const shipmentPayload = {
      customerId: data.customerId,
      shipmentMasterId: data.shipmentMasterId,
    };
    this.props.pushModalToStack({
      modalBody: (
        <ShipmentDetail
          shipmentData={data}
          shipmentPayload={shipmentPayload}
        />
      ),
      width: "100%",
      hideFooter: true,
      wrapClassName: "modal-custom-detail",
      handleSubmit: () => {},
    });
  };

  render() {
    return (
      <>
        {this.state.settingSalesDrawerVisible && 
        <SettingSalesDrawer
        {...this.state}
        {...this.props}
        updateState={(data) => {
          this.setState(data);
        }}
        updateDrawerState={(data) => { this.setState(data) }}
        />
        }

        {this.state.qualityCheckDrawerVisible && (
          <QualityCheckDrawer
            {...this.state}
            {...this.props}
            updateState={(data) => {
              this.setState(data);
            }}
          />
        )}
        <ShipmentComponent
          {...this.props}
          {...this.state}
          updateState={(data) => {
            this.setState(data);
          }}
          updateDrawerState={(data) => { this.setState(data) }}
          openDeliveryNotesDetails={this.openDeliveryNotesDetails}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    customers: state.customer.customers,
    companyInfo: state.common.companyInfo,
    userInfo: state.common.userInfo,
    allPreferences: state.common.allPreferences,
    customerDetail: state.common.customerDetail,
    soUnShippedList: state.so.soUnShippedList,
    freightList: state.so.freightList,
    countries: state.common.countries,
    carrierList: state.so.carrierList,
    soDetail: state.so.soDetail,
    shipmentData: state.shipment.shipmentData,
    addresses: state.customer.addresses,
    boRelationshipAddress: state.common.boRelationshipAddress,
    documentList: state.common.documentList,
    nextTxNumber: state.shipment.nextTxNumber,
    customerContactList: state.customer.contacts,
    saveLoading: state.common.saveLoading,
    detailLoading: state.common.detailLoading,
    dataTry:state.so.dataTry,
    permissions: state.auth.permissions,

  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createShipment,
      fetchSoDataForShipment,
      fetchCustomerDetailsByCustomerId,
      fetchCustomers,
      fetchAllCarriers,
      addCarrierName,
      deleteCarrierName,
      fetchAllFreightType,
      saveUserPrefrences,
      fetchCountries,
      addFreightType,
      deleteFreightType,
      showModal,
      hideModal,
      deleteAddress,
      deleteContact,
      resetSODetails,
      getShipmentByShipmentId,
      resetShipmentData,
      fetchUserPreferences,
      fetchSODetail,
      pushModalToStack,
      popModalFromStack,
      fetchDocuments,
      addDocumentName,
      deleteDocumentName,
      fetchAddresses,
      resetAddresses,
      getRelationshipAddress,
      resetCustomerDetail,
      getNextTxNumber,
      fetchAllContacts,
      fetchAllShipment,resetSoUnShiped,fetchSoDataForShipmentData,resetDataTry,resetAmendSoDataForInvoice
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ShipmentContainer);
