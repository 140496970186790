import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { popModalFromStack, pushModalToStack } from './../../../../../actions/commonActions';
import ImageUpload from '../../../../../components/modal/modalBody/product/ImageUpload';
import { fetchInventoryItemDetails,resetProductDetail } from '../../../../product/detail/action';
import { imagePreview, uploadVariantImage ,deleteImage} from './action';
import { BUCKET_NAME } from '../../../../../static/constants';
import { getAWSFileURL } from '../../../../../utils';

class ImageUploadContainer extends Component {
    constructor(props) {
        super(props);
       this.state={
        selectedVariant: "",
        variantId:0,
        imageval: [],
        link: "",
       }
    }
    componentDidMount() {
        const payload = {
            relationshipId: (this.props.companyInfo || {}).relationshipId,
            itemId: (this.props.product || {}).itemId,
           
        };
        this.props.resetProductDetail();
        this.props.fetchInventoryItemDetails(payload);

      
    }
    componentWillReceiveProps(props){
        if(Object.keys(props.productDetails)?.length && props.productDetails?.hashasVariant !== 1){

            const variantList = props.productDetails.inventoryItemVariantList[0];
    
            const initialImageVal = variantList?.inventoryItemVariantImageList?.map((val) => {
                    return {
                        ...val,
                        imageVal: getAWSFileURL(val.image, props.companyInfo.bucketName + "/image"),
                        link: (val.link)?.replaceAll("560", "200")?.replaceAll("315", "200"),
                    };
                })?.sort((a, b) => a.sNo - b.sNo);
            this.setState({
                selectedVariant: variantList.sku,
                variantId:variantList?.variantId,
                imageval: initialImageVal,
                link: "",
            });
        }
    }


    render() {
        return <ImageUpload {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
    }

}

const mapStateToProps = (state) => {
    return {
    productDetails: state.product.productDetails,
    variantImage: state.common.variantImage,
    detailLoading: state.common.detailLoading,
    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    popModalFromStack,
    fetchInventoryItemDetails,
    uploadVariantImage,
    pushModalToStack,
    imagePreview,deleteImage,resetProductDetail
    
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ImageUploadContainer);