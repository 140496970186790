import React, { useState } from "react";
import { InboxOutlined } from "@ant-design/icons";
import { Button, Input, Select, Upload, Skeleton } from "antd";
import { FormattedMessage, injectIntl } from "react-intl";
import { getAWSFileURL } from "../../../../../utils";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { ICONS } from "../../../../../static/constants";

const { Option } = Select;
const { Dragger } = Upload;

function ImageUpload(props) {
    const { productDetails, imageval } = props;

    const handleFileInputChange = (fileList) => {
        const files = Array.isArray(fileList) ? fileList : [fileList];
        props.updateState({ files });
    };

    const handleVideoInputChange = (fileList) => {
        const files = Array.isArray(fileList) ? fileList : [fileList];
        props.updateState({ videos: files });
    };

    const handleSelect = (value, obj) => {
        const selectedVariantId = Number(obj.key);

        const variantList = productDetails.inventoryItemVariantList?.find((obj) => obj.variantId === selectedVariantId);

        const initialImageVal = variantList?.inventoryItemVariantImageList
            ?.map((val) => {
                return {
                    ...val,
                    imageVal: getAWSFileURL(val.image, props.companyInfo.bucketName + "/image"),
                    link: (val.link)?.replaceAll("560", "200")?.replaceAll("315", "200"),
                };
            })
            ?.sort((a, b) => a.sNo - b.sNo);
        props.updateState({
            selectedVariant: value,
            variantId: selectedVariantId,
            imageval: initialImageVal,
            link: "",
        });
    };

    const propss = {
        name: "file",
        multiple: true,
        beforeUpload(file) {
            handleFileInputChange(file)
            return false;
        },
        onChange(info) {
            const { status } = info.file;
            if (status !== "uploading") {
                handleFileInputChange(info.fileList);
            }
        },
        onDrop(e) {
            handleFileInputChange((e.dataTransfer.files));
        },
    };
    const propsVideo = {
        name: "file",
        multiple: true,
        beforeUpload(file) {
            handleVideoInputChange(file)
            return false;
        },
        onChange(info) {
            const { status } = info.file;
            if (status !== "uploading") {
                handleVideoInputChange(info.fileList);
            }
        },
        onDrop(e) {
            handleVideoInputChange(e.dataTransfer.files);
        },
    };

    // Handle drag-and-drop end event
    const handleOnDragEnd = (result) => {
        if (!result.destination) return;

        const items = Array.from(imageval);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        let finalList = items.map((item, i) => {
            return { ...item, sNo: i }
        })
        props.updateState({
            imageval: finalList
        });
        // setImageVal(items);
    };
console.log("iuvnfijvndjvbdfijdb",imageval);

    return (
        <>
            <Skeleton loading={props.detailLoading} active paragraph={{ rows: 12 }}>
                <div className="image-container">

                    <>
                        {productDetails.hasVariant === 1 ? (
                            <div className="drawer-label">
                                <div className="pb10">
                                    <FormattedMessage id="uploadImage.variant.label" defaultMessage="" />
                                </div>
                                <Select
                                    value={props.selectedVariant}
                                    onChange={handleSelect}
                                    style={{ width: "100%" }}
                                    placeholder={props.intl.formatMessage({
                                        id: "common.select.placeholder",
                                        defaultMessage: "",
                                    })}
                                >
                                    {(productDetails.inventoryItemVariantList || []).map((item) => (
                                        <Option key={item.variantId} value={item.sku}>
                                            {`${item.attributeValue1 || ''} ${item.attributeValue2 || ''} ${item.attributeValue3 || ''}`}
                                        </Option>
                                    ))}
                                </Select>
                            </div>
                        ) : (
                            ""
                        )}
                        {imageval ?
                            <DragDropContext onDragEnd={handleOnDragEnd}>
                                <Droppable droppableId="image-list" direction="horizontal">
                                    {(provided) => (
                                        <div
                                            {...provided.droppableProps}
                                            ref={provided.innerRef}
                                            className="image_holder"
                                        >
                                            {imageval?.map((value, index) => (
                                                <Draggable key={index} draggableId={`item-${index}`} index={index}>
                                                    {(provided) => (
                                                        <div
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                        >
                                                            <React.Fragment>
                                                                <div>
                                                                {value.type === "Video" ? (
                                                                    <video width="200" controls autoPlay muted>
                                                                        <source src={value.imageVal} type="video/mp4" />
                                                                        <source src={value.imageVal} type="video/webm" />
                                                                        Your browser does not support the video tag.
                                                                    </video>
                                                                ) : value.type === "Link" || value?.link?.length ? (
                                                                    <iframe
                                                                    width="180"
                                                                    height="185"
                                                                    src={`https://www.youtube.com/embed/${value?.link}`}
                                                                    frameBorder="0"
                                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                                    allowFullScreen
                                                                  ></iframe>
                                                                ) : (
                                                                    <img src={value.imageVal} alt="" height={100} width={100} />
                                                                )} 
                                                                </div>
                                                                <div style={{zIndex:"9999999"}} onClick={()=>{
                                                                      props.showModal({
                                                                        modalBody: "Are you sure you want to delete?",
                                                                        handleSubmit: () => {
                                                                          props.deleteImage(value,props);
                                                                        }
                                                                      })
                                                                }}>
                                                                 <i className={ICONS.DELETE} />
                                                                </div>
                                                            </React.Fragment>
                                                        </div>
                                                    )}
                                                </Draggable>
                                            ))}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>
                            : ""}
                    </>


                    {props.selectedVariant && (
                        <>
                            <div className="media-section">
                                Photo
                                <div className="media-content">
                                    <Dragger {...propss}>
                                        <p className="ant-upload-drag-icon">
                                            <InboxOutlined />
                                        </p>
                                        <p className="ant-upload-text">
                                            Click or drag file to this area to upload
                                        </p>
                                        <p className="ant-upload-hint">
                                            Support for a single or bulk upload. Strictly prohibit uploading company data or other banned files.
                                        </p>
                                    </Dragger>
                                </div>
                            </div>

                            <div className="media-section">
                                Video
                                <div className="media-content">
                                    <Dragger {...propsVideo}>
                                        <p className="ant-upload-drag-icon">
                                            <InboxOutlined />
                                        </p>
                                        <p className="ant-upload-text">
                                            Click or drag file to this area to upload
                                        </p>
                                        <p className="ant-upload-hint">
                                            Support for a single or bulk upload. Strictly prohibit uploading company data or other banned files.
                                        </p>
                                    </Dragger>
                                </div>
                            </div>
                            <div>
                                Link
                                <div className="media-content">
                                    <Input
                                        value={props.link}
                                        onChange={(e) => {
                                            props.updateState({
                                                link: e.target.value,
                                            });
                                        }}
                                    ></Input>
                                </div>
                            </div>
                        </>
                    )}

                </div>

                <div className="image-btn">
                    {props.selectedVariant ? (
                        <Button
                            onClick={() => {
                                props.updateState({ submitted: true });
                                const payload = {
                                    relationshipId: props.companyInfo.relationshipId,
                                    variantId: props.variantId,
                                    bucketName: props.companyInfo.bucketName,
                                    files: props.files,
                                    video: props.videos,
                                    link: props.link,
                                    imageval: props.imageval

                                };
                                const modalData = {
                                    modalBody: <FormattedMessage id="modal.commmon.submit" defaultMessage="" />,
                                    handleSubmit: () => {
                                        props.uploadVariantImage(payload, props);
                                        props.popModalFromStack();
                                    },
                                };
                                props.pushModalToStack(modalData);
                            }}
                        >
                            <FormattedMessage id="button.save.label" defaultMessage="" />
                        </Button>
                    ) : (
                        ""
                    )}
                </div>
            </Skeleton>
        </>
    );
}

export default injectIntl(ImageUpload);
