import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import RatingAndReviewListingComp from '../../../components/product/RatingAndReview';
import {fetchReviewList,countReviewList,fetchPublishAndUnpublishList,countPublishAndUnpublish,updatePublishFeedback} from './action';
import {  popModalFromStack, pushModalToStack, showModal } from '../../../actions/commonActions';
import FeedbackDrawer from '../../drawer/inventory/FeedbackDrawer';
import { updateFeedback } from '../../drawer/inventory/FeedbackDrawer/action';
import UnboxingDrawer from '../../drawer/inventory/UnboxingDrawer';
import { deleteUnboxingItem, fetchUnboxingListCount , fetchUnboxingVideoList} from './action';

class RatingAndReviewListing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageNumber: 1,
      pageSize: 100,
      activeKey:"1",
      selectedItems:[],
      unBoxingDrawerVisible: false,
    };
  }

  componentWillReceiveProps(props) {
  
  }

  componentDidMount() {
    const payload = {
      relationshipId: (this.props.companyInfo || {}).relationshipId,
      pageNumber: 1,
      isPublished: this.state.activeKey === "1" ?  1 :0,
      pageSize: 100
    };
      this.props.fetchPublishAndUnpublishList(payload);
      this.props.countPublishAndUnpublish(payload)
 
  }

  render() {
    return (
      <>
       {this.state.unBoxingDrawerVisible && <UnboxingDrawer  {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} updateDrawerState={(data) => { this.setState(data) }}/>}
      {this.state.feedbackDrawer && <FeedbackDrawer {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />}
      <RatingAndReviewListingComp {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} updateDrawerState={(data) => { this.setState(data) }}/>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    ratingAndReviewList:state.product.ratingAndReviewList,
    ratingAndReviewCount:state.product.ratingAndReviewCount,
    publishedList:state.product.publishedList,
    publishCount:state.product.publishCount,
    listLoading:state.common.listLoading,
    permissions: state.auth.permissions,
    unBoxingVideoList:state.product.unboxingVideoList,
    unBoxingVideoCount:state.product.unboxingVideoCount,
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchReviewList,
    showModal,
    countReviewList,
    fetchPublishAndUnpublishList,
    countPublishAndUnpublish,
    updateFeedback,
    pushModalToStack,
    popModalFromStack,
    updatePublishFeedback,
    fetchUnboxingVideoList,
    fetchUnboxingListCount,
    deleteUnboxingItem
    
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(RatingAndReviewListing);
