import axios from 'axios';
import React from 'react';
import config from '../../../../../config/environmentConfig';
import { SO_ACTION_LIST, COMMON_ACTIONS } from '../../../../../static/constants';
import { showToasterMessage } from '../../../../../utils';
import StandardPerfomaInvoiceRequest from '../../../../customer/invoice/StandardPerformaInvoice';
import StandardSalesInvoice from '../../../../customer/invoice/StandardSalesInvoice';
import MultiCurrencySalesInvoice from '../../../../customer/invoice/MultiCurrencySalesInvoice';
const { lang } = require('../../../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');


export const fetchSODetail = (payload) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_DETAIL_LOADER });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/salesOrder/getSalesOrderData?salesOrderMasterId=${payload.salesOrderMasterId || payload.soMasterId}&relationshipId=${payload.relationshipId}&customerId=${payload.customerId}`)
      .then(res => {
        const { data } = res
        dispatch({ type: SO_ACTION_LIST.SO_DETAIL, data });
      })
      .catch((err) => {
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        } else {
          err.actionToCall = SO_ACTION_LIST.SO_DETAIL;
        }
      }).finally(() => {
        dispatch({
          type: COMMON_ACTIONS.HIDE_DETAIL_LOADER,
        });
      })
  }
}

export const fetchAmendSODetail = (payload) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_DETAIL_LOADER });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/amendSalesOrder/getAmendSalesOrderDetails?amendSalesOrderMasterId=${payload.amendSalesOrderMasterId || 0}&relationshipId=${payload.relationshipId}&customerId=${payload.customerId}`)
      .then(res => {
        const { data } = res
        dispatch({ type: SO_ACTION_LIST.AMEND_SO_DETAIL, data });
      })
      .catch((err) => {
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        } else {
          err.actionToCall = SO_ACTION_LIST.AMEND_SO_DETAIL;
        }
      })
      .finally(() => {
        dispatch({
          type: COMMON_ACTIONS.HIDE_DETAIL_LOADER,
        });
      })
  }
}


export const resetSODetails = () => {
  return dispatch => {
    dispatch({ type: SO_ACTION_LIST.RESET_SO_DATA });
  }
}

// export const sendMail = (formData) => {
//   const configObj = {
//     headers: {
//       'Content-Type': 'multipart/form-data'
//     }
//   }
//   return dispatch => {
//     dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
//     return axios
//       .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/emailManager/emailSalesOrderPdf`, formData, configObj)
//       .then(res => {
//         showToasterMessage({ messageType: 'success', description: 'Mail sent successfully.' })
//         ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//       })
//       .catch(err => {
//         showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
//       })
//   }
// }

export const approveSO = (payload, props) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/salesOrder/approveSO`, payload)
      .then(res => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        dispatch({ type: SO_ACTION_LIST.SO_LIST_PAGE_RESET });
        props.fetchSalesOrders({...props, relationshipId: payload.relationshipId});
        showToasterMessage({
          description: (res || {}).message || 'Approved successfully.',
          messageType: 'success'
        })
      })
      .catch((err = {}) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (err.__isRetryRequest) {
        } else {
          showToasterMessage({
            description: lang[((err.response || {}).data || {}).message] || 'Some error occurred'
          })
        }
      })
  }
}

export const uploadStamp = (payload) => {
  return dispatch => {
    let formData = new FormData();
    formData.append("file", payload.file);
    formData.append('objectKey', payload.objectKey);
    formData.append('rid', payload.rid);
    formData.append('customerId', payload.customerId);
    formData.append('soMasterId', payload.salesOrderMasterId);
    formData.append('bucketName', payload.bucketName);
    formData.append("contentType", "multipart/form-data;");
    formData.append("contentLength", "1234");
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/salesOrder/saveOrUpdateStamp`,
        formData, {
        headers: {
          'content-type': 'multipart/form-data'
        }
      })
      .then(res => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        dispatch({ type: SO_ACTION_LIST.SO_DETAIL, data: res.data });
      })
      .catch(err => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
  }
}

export const deleteStamp = (payload, props) => {

  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/salesOrder/deleteStamp?relationshipId=${payload.relationshipId}&customerId=${payload.customerId}&soMasterId=${payload.salesOrderMasterId}&objectKey=${payload.objectKey}&folderName=${payload.folderName}&bucketName=${payload.bucketName}`)
      .then(res => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        props.fetchSODetail(payload);
        showToasterMessage({
          description: (res || {}).message || 'Deleted successfully.',
          messageType: 'success'
        })
      })
      .catch((err = {}) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (err.__isRetryRequest) {
        } else {
          showToasterMessage({
            description: lang[((err.response || {}).data || {}).message] || 'Some error occurred'
          })
        }
      })
  }
}

export const checkAndConvertToPerformaInvoice = (props, payload) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/customerInvoice/countProformaInvBySOMasterId?salesOrderMasterId=${payload.salesOrderMasterId || payload.soMasterId}&relationshipId=${payload.relationshipId}&customerId=${payload.customerId}`)
      .then(res => {
        if(res.data >= 1){
          showToasterMessage({ messageType: 'error', description: lang['proforma.invoice.exist'] })
        }else{
          props.popModalFromStack();
          props.pushModalToStack({
            modalBody: <StandardPerfomaInvoiceRequest {...props} customerId={payload.customerId} soMasterId={ payload.salesOrderMasterId} />,
            width: '100%',
            hideTitle: true,
            hideFooter: true,
            wrapClassName: 'new-transaction-wrapper'
        })
          // props.history.push({
          //     pathname:  '/admin/performa-invoice',
          //     state: {
          //         customerId: payload.customerId,
          //         soMasterId: payload.salesOrderMasterId
          //     }
          // })
        }
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        }
      })
  }
}

export const canInvoiceBeGenerated = (soDetail, props, soIds,rrItem) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/salesOrder/canInvoiceBeGenerated?relationshipId=${soDetail.relationshipId}&customerId=${soDetail.customerId}&soMasterIdArr=${soIds}&invoiceSource=1`)
      .then(res => {
        //if (res.data) {
          props.popModalFromStack()
          props.popModalFromStack();
          soDetail.isMultiCurrency
          ?
          props.pushModalToStack({
            modalBody: <MultiCurrencySalesInvoice {...props} customerId={soDetail.customerId} soMasterId= {soDetail.salesOrderMasterId} soMasterIdArr= {soDetail.salesOrderMasterId} />,
            width: '100%',
            hideTitle: true,
            hideFooter: true,
            wrapClassName: 'new-transaction-wrapper'
        })
        :
        props.pushModalToStack({
          modalBody: <StandardSalesInvoice {...props} customerId={soDetail.customerId}  soMasterId= {soDetail.salesOrderMasterId} soMasterIdArr= {soDetail.salesOrderMasterId} rrItem={rrItem} />,
          width: '100%',
          hideTitle: true,
          hideFooter: true,
          wrapClassName: 'new-transaction-wrapper'
      })
          // props.history.push({
          //   pathname: soDetail.isMultiCurrency ? 'sales-invoice-multi-currency' : '/admin/sales-invoice',
          //   state: {
          //     customerId: soDetail.customerId,
          //     soMasterId: soDetail.salesOrderMasterId,
          //     soMasterIdArr: soDetail.salesOrderMasterId
          //   }
          // })
       // }
      })
      .catch((err) => {
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        }
      })
  }
}

export const updatePRStatus = (payload, props) => {
  return dispatch => {

    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/salesOrder/updateStatusPRMessage`, payload)
      .then(res => {
        showToasterMessage({ messageType: 'success', description: 'Status updated' })
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        props.fetchSalesOrders(props);
        props.fetchSODetail && props.fetchSODetail(payload);
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        }
      })
  }
}