import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { ExpandOutlined } from "@ant-design/icons";
import { Col, Drawer, Row, Skeleton, Card, Upload, Button, message, Empty, Checkbox } from "antd";
import { CustomButton } from "../../../general/CustomButton";
import { TextBox } from "../../../general/TextBox";
import { getAWSFileURL } from "../../../../utils";
import { ICONS } from "../../../../static/constants";
import { MultipleSelectDropdown } from "../../../general/MultipleSelectDropdown";
import find from "lodash.find";
import { ErrorMsg } from "../../../general/ErrorMessage";

const { Meta } = Card;

const UnBoxingDrawer = (props) => {
  const { data , soDetail} = props;
  const itemList = soDetail?.customerSalesOrderDetailsList||[];
  const closeDrawer = () => {
    props.updateState({
        unBoxingDrawerVisible: false,
    });
  };
  const videoUrl = getAWSFileURL(data?.awsKeyName, props.companyInfo.bucketName, data?.folderName)
  
  console.log(soDetail,data ,"urlsss")
  const handleSubmit = () => {
    
      const payload ={
        ...data,
        itemList: props.selectedItems,
       url:props.videoLink,
        showOnMainPage: props?.showOnHomepage ?1 :0,
      }
     props.updateState({
      submittedOnce: true,
     })
   
    if(props?.videoLink && (((props.selectedItems||[])?.length)||props?.showOnHomepage)){
      const modalData = {
        modalBody: <FormattedMessage id="commons.create.popup.confimation.msg" defaultMessage="" />,
        handleSubmit: () => {
          props.createUnboxingVideo(payload);
          closeDrawer();
        },
      };
      props.pushModalToStack(modalData);
    }
   
  }
  const DownloadAWSVideo = () => {
   const payload ={
    folderName: data?.folderName,
    relationshipId: props.companyInfo?.relationshipId,
    bucketName:props.companyInfo?.bucketName,
    awsKeyName: data?.awsKeyName,
    fileName: `${data?.soNumber}.mp4`,
   }
   props.downloadFileFromBucket(payload);
  }
  const getCreateConfirmationModalBody = (payload) => {
    return (
      <>
        <p>
          <FormattedMessage
            id="drawer.inventory.confirmation.text"
            defaultMessage=""
          />
        </p>
      </>
    );
  };
  console.log(props?.selectedItems ,"selectedItemsss","yutubeeee")
  const handleDeleteImage = () => {
    const modalData = {
        modalBody: <FormattedMessage id="commons.create.popup.confimation.msg" defaultMessage="" />,
        handleSubmit: () => {
        const payload={
            relationshipId:props.companyInfo.relationshipId,
            unboxingId:data?.unboxingId,
            folderName: data?.folderName,
            awsKeyName: data?.awsKeyName,
            bucketName: props?.companyInfo?.bucketName,

          }
          props.deleteUnboxingVideo(payload)
        },
      };
      props.pushModalToStack(modalData);
    

  };



  const imageVal = props?.feedbackData.ecomFeedbackImages?.map((val) => {
    return {
      url: getAWSFileURL(val.image, props.companyInfo.bucketName, val.folderName),
      id: val.ecomFeedBackImageId,
    };
  });
  return (
    <Drawer
      title={`Unboxing Video (${data?.soNumber})`}
      width={720}
      zIndex={1}
      maskClosable={false}
      destroyOnClose={true}
      onClose={closeDrawer}
      className="custom-drawer"
      visible={props.unBoxingDrawerVisible}
      footer={
        <>
          <CustomButton
            intlId="confirmation.cancel"
            defaultMessage=""
            type="default"
            key={0}
            style={{ marginRight: 8 }}
            onClick={closeDrawer}
          />

          <CustomButton
            intlId="confirmation.submit"
            defaultMessage=""
            htmlType="submit"
            onClick={() => {
              handleSubmit()
            }
            }
            key={1}
          />
        </>
      }

    >
        <Row gutter={[16,16]}>

            <Col span={24}>
            {/* <label className="i-label">Uploaded Video</label> */}
            <div className="UnBoxing-Video">
                <div   className="uploadedVideo">
                <video
                        src={videoUrl}
                      
                        width={200}
                        controls
                      />
                </div>
                <div className="icon">
               <div onClick={()=>{handleDeleteImage()}}> <i className={`${ICONS.DELETE} unboxing-icon`} /></div>
               <div onClick={()=>{DownloadAWSVideo()}}> <i className={`${ICONS.DOWNLOAD} unboxing-icon`} /></div>
              
                </div>
           
                      </div>
            </Col>

            <Col span={24}>
            <label>Selected Product will display on Product Details Page</label>

            </Col>
            <Col span={6}>
            <div className='i-label'>Select Product<span className='required'>*</span></div>
      
            </Col>
            <Col span={18}>
            <MultipleSelectDropdown
                            items={ itemList|| []}
                            placeholder={'Select Product'}
                            mode='multiple'
                            onDeSelect={(id) => {
                              let tempList = props.selectedItems || [];
                              const empExits = tempList.find((obj) => Number(obj.itemId) === Number(id))
                              if (empExits) {
                                tempList = tempList.filter((obj) => Number(obj.itemId) !== Number(id));
                                props.updateState({ selectedItems: tempList });
                              }
                              


                            }}
                            valueKeyName='itemName'
                            selectedValue={(props.selectedItems || []).map((obj) => { return obj.itemId })}
                            optionKeyName='itemId'
                            onSelect={(id) => {
                              
                              let tempList = props.selectedItems || [];
                              const product = find( itemList || [],
                                            { itemId: id })
                              tempList.push({itemId:id,
                                 relationshipId: props.companyInfo?.relationshipId,
                                  inventoryItemName:product?.itemName});
                             props.updateState({
                              selectedItems:tempList,
                             })
                             
                            }}
                          />
            </Col>
            <Col span={24}>
            <Checkbox
            onChange={(e) => {
             props.updateState({
                showOnHomepage: e.target.checked
             })
            } }
          
            checked={props.showOnHomepage}
          >
          Publish video on homepage
          </Checkbox>
            
            </Col>

            <Col span={24}>
            <div className='i-label'>Video Link<span className='required'>*</span></div>
            <TextBox
            maxLength={50}
            countStyle={{ top: "38px" }}
            className="textbox-pd"
            value={props.videoLink}
            placeholder={"Youtube link of Unboxing Video"}
            onChange={(e) => {
              props.updateState({
                videoLink: e.target.value,
              });
            }}
          />
            <ErrorMsg
                            validator={() => { return !props.submittedOnce || props.videoLink  }}
                            message={'Videolink Required'} />
            </Col>
        </Row>

    
    </Drawer>
  );
};

export default UnBoxingDrawer;
