import React, { Fragment, Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input } from 'antd';
import { CustomButton } from '../../../../general/CustomButton';

class RejectApprovalComponent extends Component {


  render() {
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };

    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 24,
          offset: 0,
        },
      },
    };

    return (
      <Fragment>
        <Form  {...formItemLayout} onSubmit={this.handleSubmit} hideRequiredMark={true}>
          <div className="modal-content employee-modal-control">
          
            <Form.Item label={(<span><FormattedMessage id='modal.common.rejectApproval.reason' defaultMessage='' />  </span>)} colon={false}>
              {getFieldDecorator('reason', {
                rules: [
                  {
                    required: true,
                    message: 'Reason is required.',
                    initialValue: this.props.reason || ''
                  }
                ],
              })(<Input  placeholder={this.props.intl.formatMessage({ id: 'modal.common.rejectApproval.reason.placeholder', defaultMessage: ''  } )} />)}
            </Form.Item>
          
          </div>
          <Form.Item {...tailFormItemLayout} className="modal-footer">
            <div key={0} className='customModal-button-group'>
              <CustomButton
                intlId={'modal.contact.button.cancel'}
                defaultMessage={''}
                type={'default'}
                key={1}
                onClick={() => { this.props.hideModal();  }}
              />
              <CustomButton
                intlId={'button.save.label'}
                defaultMessage={''}
                htmlType='submit'
                key={2}
              />
            </div>
          </Form.Item>
        </Form>
      </Fragment >
    )
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const payload = {
          ...this.props.data,
          rejectionReason: values.reason,
          createdByEmpId: this.props.userInfo && this.props.userInfo.relationshipEmployeeId,
          //dateCreated: getCurrentDateForBackend(new Date())
        }
        const modalData = {
          modalBody: 'Are you sure you want to save ?',
          handleSubmit: () => {
             this.props.rejectApproval(payload);
             this.props.handleSubmit && this.props.handleSubmit();
          }
        };
        this.props.pushModalToStack(modalData);
      }
    });
  };
}

export default Form.create({ name: 'Rejec_Approval_Component' })(injectIntl(RejectApprovalComponent));
