import React from "react";
import { Steps, Button, Col, Row, Divider, Skeleton } from 'antd';
import './style.scss';
import { FormattedMessage, } from 'react-intl';
import { getMomentDateForUIReadOnly } from '../../../../../utils';
import ShippingInfo from "./ShippingInfo";
import ShippingPrices from "./ShippingPrices";
import ShippingOrderInfo from "./ShippingOrderInfo";

const CreateReturnShipmentShiprocket = (props) => {

    const { current=0, ShippingPickupInfo={}, shiprocketAwb = {} } = props;

    const returnAwbDetails = shiprocketAwb?.data?.awb_assign_status === 1 ? shiprocketAwb?.data?.response.data : {};

    const Step = Steps.Step;

    const steps = [
        {
            title: "Select",
            key: 0
        },
        {
            title: <FormattedMessage id='carriarsPrice.text' />,
            key: 1
        },
        {
            title: "Shipping Info",
            key: 2
        }
    ];

    
    return (<>
        <ShippingInfo {...props} />
        <Divider className="mt10 mb20" />

        <Steps current={current} status={props.stepError ? 'error' : 'process'}>
            {steps.map(item => <Step key={item.key} title={item.title} />)}
        </Steps>

        <div className="mt20" />

        {props.stepError ? <div className="required">{props.stepError}</div> : ''}

        {
            current === 0 ? <ShippingOrderInfo {...props} /> : ''
        }
        
        { 
            current === 1 ? <ShippingPrices {...props} /> : '' 
        }
        {
            current === 2 ? <>
                <Skeleton loading={props.detailLoading} paragraph={{ rows: 5 }} active>
                    {shiprocketAwb?.data?.awb_assign_status === 1 ? 
                        <Row>

                            <Col span={12}>
                                Courier Name
                            </Col>
                            <Col span={12}>
                                {returnAwbDetails?.courier_name? returnAwbDetails?.courier_name : '-'}
                            </Col>

                            <Col span={12}>
                                Pickup schedule date
                            </Col>
                            <Col span={12}>
                                {returnAwbDetails?.pickup_scheduled_date ?  getMomentDateForUIReadOnly({date: returnAwbDetails?.pickup_scheduled_datee}) : '' }
                            </Col>

                            <Col span={12}>
                                Awb Code 
                            </Col>
                            <Col span={12}>
                                {returnAwbDetails?.awb_code || '' }
                            </Col>
                        </Row>
                        :
                        'Some Error Occured'
                    }
                </Skeleton>
              
                <div className="fot-fix" style={{ maxWidth: '100%' }}>
                    <div className="text-center">
                        <Button 
                            type="primary"
                            onClick={()=>{
                                props.popModalFromStack();
                            }}
                        > 
                            Close
                        </Button>
                    </div>
                </div>
            </>
            : 
            ''
        }
    </>
    );
}

export default CreateReturnShipmentShiprocket;