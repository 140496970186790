import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ShipmentDetailComponent from '../../../../../components/modal/modalBody/customer/ShipmentDetail';
import { fetchDeliveryNotesDetail, canInvoiceBeGenerated, getPurchasedShipment, getShipmentLogisticInfo } from './action';
import EmailComponent from '../../../../../containers/drawer/email';
import { fetchDataIfNeeded } from '../../../../../utils';
import { fetchCustomerDetailsByCustomerId, deleteAttectedFile,popModalFromStack,pushModalToStack } from '../../../../../actions/commonActions';
import { generatePDF, downloadFileFromBucket, printPDF } from '../../../../../actions/downloadFileAction';
import { fetchAllShipment, getShipmentCount, deleteShipment } from '../../../../customer/shipment/Listing/action';
import { markAsDelivered, updateActualDeliveryDate } from '../ShipmentInformation/action';
import { getTrackingByOrderId } from '../GenerateShippingLabelShiprocket/action';
import { fetchSalesInvoiceDetail } from '../SalesInvoiceDetail/action';
class ShipmentDetail extends Component {

    constructor(props){
        super(props);
        this.state= {
            emailDrawerVisible: false,
            subject: '',
            emailBody: '',
            templateName: "Professional"
        }
    }

    componentWillMount() {
        const companyInfo = this.props.companyInfo || {};
        const payload = { relationshipId: companyInfo.relationshipId, customerId: this.props.shipmentPayload.customerId, shipmentMasterId: this.props.shipmentPayload.shipmentMasterId, orderId: this.props.shipmentPayload.shipmentMasterId,invoiceMasterId:this.props.shipmentPayload.soMasterID};
        fetchDataIfNeeded('fetchDeliveryNotesDetail', 'deliveyNotesData', this.props, payload, true);
        fetchDataIfNeeded('fetchCustomerDetailsByCustomerId', 'customerData', this.props, payload, true);
        fetchDataIfNeeded('getPurchasedShipment', 'boughtShipment', this.props, payload, true);
       if(payload.invoiceMasterId){
        // fetch so details 
        fetchDataIfNeeded('fetchSalesInvoiceDetail', 'salesInvoiceDetail', this.props, payload, true);
       }
        // this.props.getShipmentLogisticInfo(payload);
        // this.props.getTrackingByOrderId(payload);
        
    }

    render() {
        
        return <>
            {this.state.emailDrawerVisible && <EmailComponent {...this.state} {...this.props} updateState={(data) => { this.setState(data) }} />}
            <ShipmentDetailComponent {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
        </>
    }

}


const mapStateToProps = (state) => {
    return {
        companyInfo: state.common.companyInfo,
        relationshipId: state.common.companyInfo.relationshipId,
        deliveyNotesData: state.so.soDeliveyNotesDetail,
        customerData: state.common.customerDetail,
        boughtShipment: state.shipment.boughtShipment,
        detailLoading: state.common.detailLoading,
        permissions: state.auth.permissions,
        shipmentLogisticDetail: state.shipment.shipmentLogisticDetail,
        shipmentTrackingDetails: state.shipment.shipmentTrackingDetails,
        salesInvoiceDetail: state.salesInvoice.salesInvoiceDetail,

    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchDeliveryNotesDetail,
    fetchCustomerDetailsByCustomerId,
    generatePDF,
    fetchAllShipment, 
    getShipmentCount, 
    deleteShipment,
    markAsDelivered, 
    updateActualDeliveryDate, 
    canInvoiceBeGenerated, downloadFileFromBucket,
    deleteAttectedFile,
    getPurchasedShipment,
    pushModalToStack,popModalFromStack,
    printPDF,
    getShipmentLogisticInfo,
    getTrackingByOrderId,fetchSalesInvoiceDetail
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ShipmentDetail);