import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CreateReturnShipmentShiprocket from '../../../../../components/modal/modalBody/customer/CreateReturnShimentShiprocket';
import { fetchFromLocalStorage } from '../../../../../utils';
import { getCarrierPricesShiprocket, createReturnShipmentOrder, generateAwbForShipment, getShiprocketIntegratedChannel, getShiprocketAccountAddress,
  generateManifest, scheduleCourierPickup, generateShippingLabel, updateShipmentDetails, getTrackingByOrderId, generateAwbForReturnShipment
 } from './action'
import { popModalFromStack } from '../../../../../actions/commonActions';
import { LOCAL_STORAGE_KEYS, LOGISTIC_CONSTANTS } from '../../../../../static/constants';
import { fetchAllShipment } from '../../../../customer/shipment/Listing/action';
import * as find from 'lodash.find';
import { fetchAmendSODetail } from '../SODetail/action';


class CreateReturnShipment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageSize: props.data.formData?.pageSize || 25,
      pageNumber: props.data.formData?.pageNumber || 1,
      logisticName: LOGISTIC_CONSTANTS.SHIPROCKET,
      current: 0
    }
  }

  componentDidMount() {
   
    const companyInfo = fetchFromLocalStorage(LOCAL_STORAGE_KEYS.LOGGEDIN_ENTITY_DATA) && JSON.parse(fetchFromLocalStorage(LOCAL_STORAGE_KEYS.LOGGEDIN_ENTITY_DATA));
    this.props.getShiprocketIntegratedChannel({relationshipId: ((companyInfo || {}).defaultCompany || {}).relationshipId});
    this.props.getShiprocketAccountAddress({relationshipId: ((companyInfo || {}).defaultCompany || {}).relationshipId})
    const payload2 = {
      customerId: this.props.data?.txData?.customerId,
      relationshipId:this.props.companyInfo.relationshipId,
      amendSalesOrderMasterId: this.props.data?.txData?.amendSalesOrderMasterId
  }
      this.props.fetchAmendSODetail(payload2);
  }

 

  render() {
    return <CreateReturnShipmentShiprocket {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />;
  }

}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    relationshipId: state.common.companyInfo.relationshipId,
    carrierRates: state.shipment.carrierRates,
    isLoadingCarriarList: state.shipment.isLoadingCarriarList,
    buyShipmentDetails: state.shipment.buyShipmentDetails,
    boughtShipment: state.shipment.boughtShipment,
    soDetail: state.so.soDetail,
    isLoadingCarriarList: state.shipment.isLoadingCarriarList,
    detailLoading: state.common.detailLoading,
    carrierDetails: state.shipment.carrierDetails,
    shiprocketCarrierDetails: state.shipment.shiprocketCarrierDetails,
    deliveyNotesData: state.so.soDeliveyNotesDetail,
    shiprocketOrderDetails: state.shipment.shiprocketOrderDetails,
    listLoading: state.common.listLoading,
    shiprocketIntgratedChannels: state.shipment.shiprocketIntgratedChannels,
    saveLoading: state.common.saveLoading,
    detailLoading: state.common.detailLoading,
    shiprocketAccountAddress: state.shipment.shiprocketAccountAddress,
    boughtShipment: state.shipment.boughtShipment,
    amendSoDetail: state.so.amendSoDetail,
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  popModalFromStack,
  fetchAllShipment,
  getCarrierPricesShiprocket,
  createReturnShipmentOrder,
  generateAwbForShipment,
  getShiprocketIntegratedChannel,
  getShiprocketAccountAddress,
  generateManifest,
  scheduleCourierPickup,
  generateShippingLabel,
  updateShipmentDetails,
  getTrackingByOrderId,
  fetchAmendSODetail,
  generateAwbForReturnShipment,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CreateReturnShipment);
