import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Skeleton, Menu,Input, DatePicker, Tabs } from 'antd';
import PageBreadcrumb from "../../../PageBreadcrumb";
import DropdownAction from "antd/lib/dropdown";
import { getMomentDateForUIReadOnly, sortTable } from '../../../../utils';
import { CONSTANTS, ICONS } from '../../../../static/constants';
import {  DownloadOutlined } from "@ant-design/icons";
import PageHeader from '../../../Common/pageHeader';
import DailyAttendance from './DailyAttendance';
import MonthlyAttendance from './MonthlyAttendance';
const { Search } = Input;

const { TabPane } = Tabs;
const AttendanceReport = (props) => {

    const breadCrumbList = [
      {
          name: "Dashboard",
      },
      {
        name: <FormattedMessage id='sidebar.menuItems.employee'/>,
      },
      {
          name: "Attendance Report",
      }
    ];

  

    const actionMenu = (e) => {
      return (
        <Menu className="row-action-dropdown">
          <Menu.Item key="0" value="title">
            Actions
          </Menu.Item>

          <Menu.Divider />

          <Menu.Item
            key="1"
            value="edit"
            onClick={() => {
            
            }}
          >
            <i className={ICONS.EDIT} />
            Edit
          </Menu.Item>

          {e.isAccountHolder ? (
            <Menu.Item
              key="2"
             
            >
              <i className={ICONS.DELETE} />
              Delete
            </Menu.Item>
          ) : (
            ""
          )}

        
        </Menu>
      );
    };

    

    return (
      <>
        <PageHeader {...props}
          pageName="Attendance Report"
          breadCrumbList={breadCrumbList}
        />

        <div className="view-container">
          <Tabs type="line"
            onChange={(key) => {
                // callTabApi(key, props);
              }
            }
          >
            <TabPane
              tab="Daily Attendance"
              key="daily_attendance"
            >
              <DailyAttendance {...props} />
            </TabPane>

            <TabPane
              tab="Monthly Attendance"
              key="monthly_attendance"
            >
              <MonthlyAttendance {...props} />
            </TabPane>
          </Tabs>
        </div>

      </>
    );
};




export default injectIntl(AttendanceReport);
