import React from "react";
import { Button, Col, Row, Skeleton, Radio } from 'antd';
import './style.scss';
import { CONSTANTS, LIST_CONSTANTS } from '../../../../../static/constants';
import { showToasterMessage, } from '../../../../../utils';
import * as find from 'lodash.find';
import moment from "moment-timezone";
import { Dropdown } from "../../../../general/Dropdown";

const ShippingOrderInfo = (props) => {

    const { shiprocketCarrierDetails={}, amendSoDetail={} } = props;
    const {txData={}} = props.data;

    const { data: { available_courier_companies = [] } = {} } = shiprocketCarrierDetails || {};


    const getRatesForCarriers = (orderId) =>{
        const {deliveyNotesData} = props;
        const shippingAddress = find(deliveyNotesData.boLocationShipmentList || [], { locationType: CONSTANTS.SHIPPING_ADDRESS }) || {};

        const payloadShipRocket = {
          relationshipId: props.companyInfo?.relationshipId || 0,
          pickup_postcode: shippingAddress?.zipCode || '227817',
          delivery_postcode: props.selectedShippingAddress?.pin_code || '',
          cod: 0,
          weight: deliveyNotesData.weight,
          length: deliveyNotesData.length,
          breadth: deliveyNotesData.width,
          height: deliveyNotesData.height,
          order_id: orderId,
          is_return: 1
        }

        props.getCarrierPricesShiprocket(payloadShipRocket);
    }

    return (
        <>
            <Skeleton loading={props.detailLoading} paragraph={{ rows: 5 }} active>
                <Row gutter={[16, 16]}>
                    <Col span={12}>
                        Select Channel
                    </Col>
                    <Col span={12}>
                        <Dropdown
                            items={props.shiprocketIntgratedChannels || []}
                            valueKeyName='name'
                            optionKeyName='id'
                            value={''}
                            placeholder='Select Channel'
                            onSelect={(optionValue, option) => {
                                let selectedChannel = find(props.shiprocketIntgratedChannels || [], { channel_id: option.value }) || {};
                                props.updateState({
                                    selected: selectedChannel,
                                })
                            }}
                        />
                    </Col>

                    <Col span={12}>
                        Return To Address<span className="required">*</span>
                    </Col>
                    <Col span={12}>
                        <Dropdown
                            items={props.shiprocketAccountAddress || []}
                            optionKeyName='id'
                            valueKeyName='address'
                            valueKeyName2='pickup_location'
                            addressLineKey1='address_2'
                            addressLineKey2='city'
                            addressLineKey3='state'
                            addressLineKey4='pin_code'
                            optionClassAddress='custom-address-dropdown'
                            className='vcustom-dropdown-width'
                            value={props.selectedAddress || ''}
                            placeholder='Select Pickup Address'
                            onSelect={(optionValue, option) => {
                                props.updateState({
                                    selectedShippingAddress: (props.shiprocketAccountAddress || []).find(obj=>obj.id === optionValue),
                                })
                            }}
                        />
                    </Col>

                    <Col span={12}>
                        Return Reason<span className="required">*</span>
                    </Col>
                    <Col span={12}>
                        <Dropdown
                            items={LIST_CONSTANTS.PRODUCT_RETURN_REASONS || []}
                            value={props.returnReason || ''}
                            placeholder='Select return reason'
                            onSelect={(optionValue, option) => {
                                props.updateState({
                                    returnReason: optionValue,
                                })
                            }}
                        />
                    </Col>
                    <Col span={12}>
                            Performa Quality Check<span className="required">*</span>
                    </Col>
                    <Col span={12}>
                        <Radio.Group 
                            onChange={(e)=>{
                                props.updateState({qualityCheck: e.target.value})
                            }} 
                            value={props.qualityCheck || 'Yes'}
                        >
                            <Radio value={'Yes'}>Yes</Radio>
                            <Radio value={'No'}>No</Radio>
                        </Radio.Group>
                    </Col>
                    <Col span={12}>
                        Payment Method<span className="required">*</span>
                    </Col>
                    <Col span={12}>
                        <Radio.Group 
                            onChange={(e)=>{
                                props.updateState({selectedPaymentMethod: e.target.value})
                            }} 
                            value={props.selectedPaymentMethod  || 'Prepaid'}
                        >
                            <Radio value={'Prepaid'}>Prepaid</Radio>
                            <Radio value={'COD'}>COD(Cash On Delivery)</Radio>
                        </Radio.Group>
                    </Col>
                </Row>
            </Skeleton>
            <div className="fot-fix" style={{ maxWidth: '100%' }}>
                <div className="text-center">
                    <Button 
                        loading={props.saveLoading}
                        type="primary"
                        onClick={()=>{
                            // if(!props.selectedShippingAddress || !props.qualityCheck ){
                            //     return showToasterMessage({ description: 'Please select required fields' });
                            // }
                            const customerPickupAddress = props.deliveyNotesData?.boLocationShipmentList?.find(obj=>obj.locationType === CONSTANTS.SHIPPING_ADDRESS)

                            const {selectedShippingAddress = {}} = props;
                            const returnPayload = {
                                order_id: props.deliveyNotesData.shipmentMasterId,
                                order_date: moment().format(CONSTANTS.BACKEND_FORMAT_FORMAT_SHORT),
                                channel_id: props.selectedChannel,
                                pickup_customer_name: (txData.customerName || "").split(" ")[0],
                                pickup_last_name: (txData.customerName || "").split(" ")[1],
                                pickup_address: customerPickupAddress?.streetAddress1 || '',
                                pickup_address_2: customerPickupAddress?.streetAddress2 || '',
                                pickup_city: customerPickupAddress?.cityName || '',
                                pickup_state: customerPickupAddress?.stateName || '',
                                pickup_country: customerPickupAddress?.countryName || '',
                                pickup_pincode: customerPickupAddress?.zipCode || '',
                                pickup_email: customerPickupAddress.emailAddress || "",
                                pickup_phone: customerPickupAddress.cellPhone || "",
                                // pickup_phone: amendSoDetail?.additionalInfoList?.length ? (amendSoDetail?.additionalInfoList[0]?.customerPhone || amendSoDetail?.additionalInfoList[0]?.cellPhone ) : "9999999999",

                                pickup_isd_code: "91",

                                shipping_customer_name: selectedShippingAddress?.name || '',
                                shipping_last_name: "",
                                shipping_address: selectedShippingAddress?.address || '',
                                shipping_address_2: selectedShippingAddress?.address_2 || '',
                                shipping_city: selectedShippingAddress?.city || '',
                                shipping_country: selectedShippingAddress?.country || '',
                                shipping_pincode: selectedShippingAddress?.pin_code || '',
                                shipping_state: selectedShippingAddress?.state || '',
                                shipping_email: selectedShippingAddress?.email || '',
                                shipping_isd_code: "91",
                                shipping_phone: selectedShippingAddress?.phone || '',

                                order_items: amendSoDetail?.customerAmendSalesOrderDetailsList.map(
                                    (product)=> {
                                        return {
                                            name: `${product.itemName} ${product.itemVariantName ? `- ${product.itemVariantName}` : ''}`,
                                            sku: product.sku || '',
                                            units: product.quantity || 0,
                                            selling_price: product.anItemSalePrice || 0,
                                            discount: 0,
                                            hsn: product.hsnCode || '0',
                                            return_reason: props.returnReason || 'NA',
                                            qc_enable: props.qualityCheck === 'No' ? false : true,
                                            qc_color: props.qcColor || '',
                                            qc_brand: props.qcBrand || '',
                                            qc_serial_no: props.qcSerialNumber || '',
                                            qc_ean_barcode: props.eanBarcode || '',
                                            qc_size: props.qcSize || '',
                                            qc_product_name: `${product.itemName} ${product.itemVariantName ? `- ${product.itemVariantName}` : ''}`,
                                            // qc_product_image: "https://s3-ap-southeast-1.amazonaws.com/kr-multichannel/1636713733zxja.png"
                                        }
                                    }),
                                payment_method: props.selectedPaymentMethod || 'Prepaid',
                                total_discount: amendSoDetail?.totalDiscount || "0.00",
                                sub_total: amendSoDetail?.salesOrderTotalAmount || "0.00",
                                length: props.deliveyNotesData?.length,
                                breadth: props.deliveyNotesData?.width,
                                height: props.deliveyNotesData?.height,
                                weight: props.deliveyNotesData?.weight
                              }
                            props.createReturnShipmentOrder(returnPayload, props, getRatesForCarriers);
                        }}
                    > 
                        Create Return Order 
                    </Button>
                </div>
            </div>
        </>
    );
}

export default ShippingOrderInfo;