import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PackageComponent from '../../../../components/customer/salesOrder/package';
import { CONSTANTS, CONSTANTS_TRANSACTIONS } from '../../../../static/constants';
import { GRID_PREFERENCE_CONSTANTS } from '../../../../static/agGridConstants';
import { fetchSoDataForPackage, createPackage, fetchUnpackedSalesOrders, resetSOUnpackedList, getNextTxNumber } from './action';
import { showModal, hideModal, pushModalToStack, popModalFromStack, fetchAllEmployees, fetchCustomerDetailsByCustomerId,
  deleteAddress, deleteContact, fetchUOM, addUOM, deleteUOM, fetchUserPreferences, saveUserPrefrences, fetchAddresses
 } from '../../../../actions/commonActions';
 import { fetchSalesOrders } from '../Listing/action';
 import { fetchCustomers } from '../../Listing/action';
import { fetchDataIfNeeded, fetchPaginationDataIfNeeded } from '../../../../utils';
import PackageDetail from '../../../modal/modalBody/customer/PackageDetail';
import QualityCheckDrawer from '../../../../containers/drawer/qualityCheck/index';
import { fetchSOPackegeDetail, resetSOPackegeDetail, resetSOPackegeData } from '../../../modal/modalBody/customer/PackageDetail/action';
import * as find from 'lodash.find';
import { fetchAllContacts } from '../../Listing/ShowConact/action';
import { updateUserStatus } from '../../package/Listing/action';
import { fetchAllPackage } from '../../package/Listing/action';
import * as filter from 'lodash.filter';
import SettingSalesDrawer from '../../../drawer/customer/settingSalesDrawer';

import { fetchAmendSoDataForInvoice ,resetAmendSoDataForInvoice} from '../../invoice/StandardSalesInvoice/action';
class PackageContainer extends Component {
  constructor(props) {
    const allRelationshipBillingAddress = filter((props.companyInfo || {}).boLocationList || [], { locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS }) || [];
    const relationshipBillingAddress = find(allRelationshipBillingAddress, { isDefault: 1 });
    super(props);
    this.state = {
      displayName:"drawer.settingDrawer.headingNamePackage",
      viewName:"Package",
      docName:"Package",
      tabDocumentOptions: { txType: CONSTANTS_TRANSACTIONS.PACKAGE, label: 'Package', numberExample: 'PKG-1', numberInfo: 'CM-CustomerNumber-DDMMYY' },

      packageDate: new Date(),
      packageType: props.packageType,
      soData : null,
      pageSize: 20,
      pageNumber: 1,
      inheritedTxColumn: [],
      forceHideTxColumn: [],
      isConvertFromSO: props.soMasterId ? true : false,
     // qualityChecklistStatus: TX_STATUS.NOT_STARTED,
      txPreferences: { txType: "salesOrderPackage", templateName: "standard" },
      txColumns: [],
      txColumnSetting: {},
      txMandatoryColumns: [
        'quantity',
        'quantityPacked',
        'uomId',
        'qtyToPack'
      ],
      txAvailableColumn: {
        product: true,
        quantity: true,
        quantityPacked: true,
        qtyToPack: true,
        description: true,
        uom: true,
      },
      relationshipBillingAddress,
      addressInti:false,
      dimensionUnit: "centimeter",
      length: 0,
      width: 0,
      height: 0,
      weight: 0,
      weightUnit: "kilograms"
    }
  }
  componentDidMount() {
    const companyInfo = this.props.companyInfo || {};
    const payload = {
      customerId: this.props.customerId ,
      soMasterId: this.props.soMasterId ,
    
      salesOrderMasterId: this.props.soMasterId  ,
      salesOrderMasterIdFromPackage: this.props.salesOrderMasterId  ,
      soPackageMasterId: this.props.soPackageMasterId ,
      relationshipId: companyInfo.relationshipId,
      userId: (this.props.userInfo || {}).userId,
      amendSoMasterId : this.props.amendSoMasterId,
      pageSize: 20,
      isCompact:true,
      pageNumber: 1,
    };
    this.setState({
      packageBy:this.props.userInfo?.firstName + "" + this.props.userInfo?.lastName
    })
    this.props.resetAmendSoDataForInvoice();
    this.props.resetSOPackegeDetail();
    this.props.resetSOPackegeData();
    this.props.resetSOUnpackedList();
    payload.customerId && fetchDataIfNeeded('fetchUnpackedSalesOrders', 'soList', this.props, payload);
    fetchDataIfNeeded('fetchAllEmployees', 'employees', this.props, payload);
    payload.soMasterId && fetchDataIfNeeded('fetchSoDataForPackage', 'soPackageData', this.props, payload, true);
    payload.amendSoMasterId &&fetchDataIfNeeded("fetchAmendSoDataForInvoice","amendSoInvoiceData",this.props,payload,true);
    payload.customerId && this.props.fetchAddresses({...payload, locationType: CONSTANTS.SHIPPING_ADDRESS});
    payload.customerId && this.props.fetchAddresses({...payload, locationType: CONSTANTS.BILLING_ADDRESS});
    fetchDataIfNeeded('fetchUOM', 'uomList', this.props, payload);
    payload.customerId && fetchDataIfNeeded('fetchCustomerDetailsByCustomerId', 'customerDetail', this.props, payload, true);
    fetchDataIfNeeded('fetchUserPreferences', 'allPreferences', this.props, payload);
    payload.soPackageMasterId && fetchDataIfNeeded('fetchSOPackegeDetail', 'soPackageDetail', this.props, payload);
    fetchPaginationDataIfNeeded('fetchCustomers', 'customers', this.props, {...payload, isCompact: true}, true);
    if (!this.props.update) {
      fetchDataIfNeeded('getNextTxNumber', 'nextTxNumber', this.props, payload);
    }
  }

  componentWillReceiveProps(props) {

    if (!this.props.soData && (props.soPackageData || {}).salesOrderMasterId && (props.customerDetail || {}).customerId) {
      let shippingAddress = find((props.soPackageData.boLocationSOList || []), { locationType: CONSTANTS.SHIPPING_ADDRESS }) || {};
      let billingAddress = find((props.soPackageData.boLocationSOList || []), { locationType: CONSTANTS.BILLING_ADDRESS }) || {}
      let selectedContact = (props.soPackageData.additionalInfoList || [])[0] || {};
      let relationshipBillingAddress = find((props.soPackageData.boLocationSOList || []), { locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS }) || {}
      delete selectedContact.additionalInfoId;
      delete billingAddress.locationSOId;
      delete shippingAddress.locationSOId;
      delete relationshipBillingAddress.locationSOId;
      const inheritedTxColumn = [];
      const _emptyColumnCheck = (item) => {
        if (inheritedTxColumn.indexOf('itemName') < 0 && item.itemName) {
          inheritedTxColumn.push('itemName');
        }
        if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.DESCRIPTION) < 0 && item.description) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.DESCRIPTION);
        }
      }
      props.soPackageData.customerSalesOrderDetailsList.map((ele) => {
        ele.qtyBeforeUpdate = ele.quantity;
        ele.qtyToPack = ele.qtyToPack || ele.quantityToPack;
        ele.quantityOrdered = ele.quantityOrdered || ele.quantity;
        _emptyColumnCheck(ele);
        return ele;
      });
      const forceHideTxColumn = [];
      if (inheritedTxColumn.indexOf('itemName') < 0) {
        forceHideTxColumn.push('itemName');
      }
      if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.DESCRIPTION) < 0) {
        forceHideTxColumn.push(GRID_PREFERENCE_CONSTANTS.DESCRIPTION);
      }
      console.log(selectedContact,props.soPackageData,"ggggggg");
      
      this.setState({
        selectedContact:selectedContact,
        billingAddress,
        shippingAddress,
        relationshipBillingAddress,
        soPackageData: props.soPackageData,
        remarksInternal: props.soPackageData.remarksInternal,
        remarksCustomer: props.soPackageData.remarksCustomer,
        qualityCheckList: props.soPackageData.qualityCheckList,
        deliveryDate: props.soPackageData.deliveryDate ? new Date(props.soPackageData.deliveryDate) :  new Date(),
        packageDate: props.soPackageData.packageDate ? new Date(props.soPackageData.packageDate) :  new Date(),
        inheritedTxColumn: inheritedTxColumn,
        forceHideTxColumn: forceHideTxColumn,
        tableDataReRendered: true,
        customerPreOrderMasterId:props.soPackageData?.customerPreOrderMasterId || 0
      });
    }

    if (!this.props.soData && (props.amendSoInvoiceData || {}).amendSalesOrderMasterId && (props.customerDetail || {}).customerId) {
      let shippingAddress = find((props.amendSoInvoiceData.boLocationAmendSOList || []), { locationType: CONSTANTS.SHIPPING_ADDRESS }) || {};
      let billingAddress = find((props.amendSoInvoiceData.boLocationAmendSOList || []), { locationType: CONSTANTS.BILLING_ADDRESS }) || {}
      let selectedContact = (props.amendSoInvoiceData.additionalInfoList || [])[0] || {};
      let relationshipBillingAddress = find((props.amendSoInvoiceData.boLocationAmendSOList || []), { locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS }) || {}
      delete selectedContact.additionalInfoId;
      delete billingAddress.locationSOId;
      delete shippingAddress.locationSOId;
      delete relationshipBillingAddress.locationSOId;
      const inheritedTxColumn = [];
      const _emptyColumnCheck = (item) => {
        if (inheritedTxColumn.indexOf('itemName') < 0 && item.itemName) {
          inheritedTxColumn.push('itemName');
        }
        if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.DESCRIPTION) < 0 && item.description) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.DESCRIPTION);
        }
      }
      props.amendSoInvoiceData.customerAmendSalesOrderDetailsList.map((ele) => {
        ele.qtyBeforeUpdate = ele.quantity;
        ele.qtyToPack = ele.qtyToPack || ele.quantityToPack;
        ele.quantityOrdered = ele.quantityOrdered || ele.quantity;
        _emptyColumnCheck(ele);
        return ele;
      });
      const forceHideTxColumn = [];
      if (inheritedTxColumn.indexOf('itemName') < 0) {
        forceHideTxColumn.push('itemName');
      }
      if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.DESCRIPTION) < 0) {
        forceHideTxColumn.push(GRID_PREFERENCE_CONSTANTS.DESCRIPTION);
      }
      this.setState({
        selectedContact:selectedContact,
        billingAddress,
        shippingAddress,
        relationshipBillingAddress,
        amendSoInvoiceData: props.amendSoInvoiceData,
        remarksInternal: props.amendSoInvoiceData.remarksInternal,
        remarksCustomer: props.amendSoInvoiceData.remarksCustomer,
        qualityCheckList: props.amendSoInvoiceData.qualityCheckList,
        deliveryDate: props.amendSoInvoiceData.deliveryDate ? new Date(props.amendSoInvoiceData.deliveryDate) :  new Date(),
        packageDate: props.amendSoInvoiceData.packageDate ? new Date(props.amendSoInvoiceData.packageDate) :  new Date(),
        inheritedTxColumn: inheritedTxColumn,
        forceHideTxColumn: forceHideTxColumn,
        tableDataReRendered: true,
        customerPreOrderMasterId:props.amendSoInvoiceData?.customerPreOrderMasterId || 0
      });
    }


    if(this.state.boContactList && this.state.boContactList?.length){
      const selectedContact = (this.state.boContactList || []).find(x => x.isPrimaryContact) || {};
      this.setState({
        selectedContact:selectedContact
      })
    }


    if(props.addresses  && !this.state.addressInti){
      
      if(props.addresses.BillingAddress && props.addresses.BillingAddress.length){
        let defaultBillingAddress = {};
        if(!(this.props.billingAddress || {}).streetAddress1){
          defaultBillingAddress = find(props.addresses.BillingAddress, { isDefault: 1 }) || {};
        }
        this.setState({
          allBillingAddress: props.addresses.BillingAddress,
          billingAddress: defaultBillingAddress,
          addressInti:true
        });
      }
      if(props.addresses.ShippingAddress && props.addresses.ShippingAddress.length)
      {
        
        let defaultShippingAddress = {};
        if(!(this.props.shippingAddress || {}).streetAddress1){
          defaultShippingAddress = find(props.addresses.ShippingAddress, { isDefault: 1 }) || {};
        }
        this.setState({
          allShippingAddress: props.addresses.ShippingAddress,
          shippingAddress:defaultShippingAddress,
          addressInti:true
        });
      }
    }

    

    if (!this.state?.soPackageData &&(props.soPackageDetail || {}).salesOrderMasterId && (props.customerDetail || {}).customerId) {

      let shippingAddress = find((props.soPackageDetail.boLocationPackageList || []), { locationType: CONSTANTS.SHIPPING_ADDRESS }) || {};
      let billingAddress = find((props.soPackageDetail.boLocationPackageList || []), { locationType: CONSTANTS.BILLING_ADDRESS }) || {}
      let selectedContact = (props.soPackageDetail.additionalInfoList || [])[0] || {};
      let relationshipBillingAddress = find((props.soPackageDetail.boLocationPackageList || []), { locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS }) || {}
      delete selectedContact.additionalInfoId;
      delete billingAddress.locationSOId;
      delete shippingAddress.locationSOId;
      delete relationshipBillingAddress.locationSOId;
      props.soPackageDetail.customerSalesOrderDetailsList = props.soPackageDetail.customerSOPackageDetailsList;
      props.soPackageDetail.customerSalesOrderDetailsList.map((ele) => {
        ele.qtyBeforeUpdate = ele.quantity;
        ele.qtyToPack = ele.quantityPacked;
        ele.quantityOrdered = ele.quantityOrdered || ele.quantity;
        ele.quantityPacked = ele.quantityFromAllPackages- ele.quantityPacked;
        return ele;
      });
      this.setState({
        update: props.update,
        soPackageMasterId: props.update ? props.soPackageDetail.soPackageMasterId : 0,
        version: props.soPackageDetail.version,
        selectedContact,
        billingAddress,
        packageType: 'update',
        customerName:props.soPackageDetail.companyName,
        shippingAddress,
        relationshipBillingAddress,
        soPackageData: {...props.soPackageDetail,customerName: props.soPackageDetail.companyName},
        soData: props.soPackageDetail,
        soNumber: (props.soPackageDetail || {}).salesOrderNumber,
        remarksInternal: props.soPackageDetail.remarksInternal,
        remarksCustomer: props.soPackageDetail.remarksCustomer,
        qualityCheckList: props.soPackageDetail.qualityCheckList,
        deliveryDate: props.soPackageDetail.deliveryDate ? new Date(props.soPackageDetail.deliveryDate) :  new Date(),
        packageDate: props.soPackageDetail.packageDate ? new Date(props.soPackageDetail.packageDate) :  new Date(),
        length: props.soPackageDetail.length,
        width: props.soPackageDetail.width,
        height: props.soPackageDetail.height,
        dimensionUnit: props.soPackageDetail.dimensionUnit,
        weight: props.soPackageDetail.weight,
        weightUnit: props.soPackageDetail.weightUnit,
        measurementUnit: props.soPackageDetail.measurementUnit,
        packageBy: props.soPackageDetail.packageBy,
        packageNumber: props.update ? props.soPackageDetail.packageNumber : null,
        nextTxNumber: props.soPackageDetail.packageNumber,
        customerPreOrderMasterId:props.soPackageDetail?.customerPreOrderMasterId || 0
      });

      setTimeout(()=>{
        this.props.resetSOPackegeDetail();
      },200)
    }

    if (props.allPreferences && props.allPreferences.length) {
      let txPreferences = find(props.allPreferences, { txType: "salesOrderPackage", templateName: "standard" }) || {};
      if (txPreferences) {
        let txColumns = txPreferences.gridPreference ? ((JSON.parse(txPreferences.gridPreference)) || {}).columnList || [] : [];
        txColumns = txColumns.length ? txColumns: this.state.txMandatoryColumns;
        const txColumnSetting = txPreferences.gridPreference ? ((JSON.parse(txPreferences.gridPreference)) || {}).columnSetting || {} : {};        
        const isColumnFitTable = (Object.keys(txColumnSetting).length || this.state.isColumnFitTable === false) ? false: true;
        let tablePreference = txPreferences.tablePreference;
        this.setState({
          tablePreference,
          isColumnFitTable,
          preferenceId: txPreferences.preferenceId,
          txColumns,
          txColumnSetting,
          txPreferences
        });
      }else{
        this.setState({
          txColumns: (this.state.txMandatoryColumns || []).concat('product', 'description')
        })
      }
    }
    if (props.customerContactList && props.customerContactList.length) {
      this.setState({
        boContactList: props.customerContactList
      });
    }
  }
  
  openPackageDetails = (data) =>{
    const packagePayload = {
        customerId: data.customerId,
        salesOrderMasterId: data.salesOrderMasterId,
        soPackageMasterId: data.soPackageMasterId,
        amendSalesOrderMasterId:data.amendSalesOrderMasterId
    }
    this.props.pushModalToStack({
        modalBody: <PackageDetail packagePayload={ packagePayload } />,
        // modalBody: <PackageDetail  {...this.props} {...this.state} packagePayload={ packagePayload } />,
        width: '100%',
        hideFooter: true,
        wrapClassName:'modal-custom-detail',
    });
}

  render() {
    return (
      <>
        {this.state.settingSalesDrawerVisible && 
        <SettingSalesDrawer
        {...this.state}
        {...this.props}
        updateState={(data) => {
          this.setState(data);
        }}
        updateDrawerState={(data) => { this.setState(data) }}
        />
        }

         {this.state.qualityCheckDrawerVisible || this.props.qualityCheckDrawerVisible && <QualityCheckDrawer {...this.state} {...this.props} updateState={(data) => { this.setState(data) }} />}
        <PackageComponent
          {...this.props}
          {...this.state}
          updateDrawerState={(data) => { this.setState(data) }}
          updateState={(data) => { this.setState(data) }}
          openPackageDetails={this.openPackageDetails}
        />
      </>
    );
  }

}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    userInfo: state.common.userInfo,
    soList: state.so.soUnpackedList,
    employees: state.common.employees,
    soPackageData: state.so.soPackageData,
    customerDetail: state.common.customerDetail,
    uomList: state.common.uomList,
    allPreferences: state.common.allPreferences,
    addresses: state.customer.addresses,
    soPackageDetail: state.so.soPackageDetail,
    customers: state.customer.customers,
    nextTxNumber: state.package.nextTxNumber,
    customerContactList: state.customer.contacts,
    permissions: state.auth.permissions,
    saveLoading:state.common.saveLoading,
    detailLoading: state.common.detailLoading,
    amendSoInvoiceData: state.so.amendSoInvoiceData,

  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchCustomerDetailsByCustomerId,
  createPackage,
  fetchCustomers,
  fetchAllEmployees,
  fetchUOM,
  addUOM, 
  deleteUOM,
  fetchUserPreferences, 
  saveUserPrefrences,
  fetchSoDataForPackage,
  pushModalToStack,
  popModalFromStack,
  showModal,
  hideModal,
  deleteAddress, 
  fetchSalesOrders,
  deleteContact,
  fetchSOPackegeDetail,
  resetSOPackegeDetail,
  resetSOPackegeData,
  fetchUnpackedSalesOrders, 
  resetSOUnpackedList,
  fetchAddresses,
  getNextTxNumber,
  fetchAllContacts,
  updateUserStatus ,
  fetchAllPackage,
  fetchAmendSoDataForInvoice,resetAmendSoDataForInvoice
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PackageContainer);
