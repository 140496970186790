import React, { Fragment, useMemo } from "react";
import { Empty, Skeleton, Switch, Pagination, Dropdown, Menu } from "antd";
import { injectIntl, FormattedMessage } from "react-intl";
import RatingPreview from "../../modal/modalBody/product/RatingPreview";
import ReactHtmlParser from 'react-html-parser';
import { getAWSFileURL, getMomentDateForUIReadOnly, getPermissionData } from "../../../utils";
import { CONSTANTS, ICONS, MODULE_CODED_VALUES } from "../../../static/constants";
import SODetail from "../../../containers/modal/modalBody/customer/SODetail";
const format = CONSTANTS.DISPLAY_DATE_FORMAT;

const UnboxingVideoListing = (props) => {
  const { unBoxingVideoList, companyInfo, pageNumber, permissions } = props;
  const primaryPerm = (permissions && permissions.primary) || [];
  const permissionsData = useMemo(() => {
    return getPermissionData(primaryPerm, MODULE_CODED_VALUES.UOM);
  }, [])
  console.log(props ,"hhhgh")
  const showTotal = (total) => {
    return (
      props.intl.formatMessage({
        id: "pagination.text.total",
        defaultMessage: "",
      })
      +
      ` ${total} ` +
      props.intl.formatMessage({
        id: "pagination.text.items",
        defaultMessage: "",
      })
    );
  };
  const loadPageData = (pageNumber, pageSize) => {
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      pageNumber: pageNumber,
      pageSize: pageSize,
    };
    props.fetchPublishAndUnpublishList({ ...payload, isPublished: 1 });

  };

  const handlePageSizeChange = (pageNumber, pageSize) => {

    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      pageNumber: pageNumber,
      pageSize: pageSize,
    };
    props.fetchPublishAndUnpublishList({ ...payload, isPublished: 1 });
    props.updateState({ pageSize: pageSize, pageNumber: pageNumber });
  };
  function removeTags(html) {
    return html.replace(/<[^>]+>/g, '');
  }
  const openLongtText = (title, Text) => {
    props.showModal({
      title: title,
      modalBody: <div style={{ maxHeight: '550px', overflow: 'auto' }}>{ReactHtmlParser(Text)}</div>,
      hideFooter: true,
      width: '70%'
    })
  }
  const openSoDetails = (data) => {
    const soPayload = {
      customerId: data.customerId,
      salesOrderMasterId: data.salesOrderMasterId,
    };
    props.pushModalToStack({
      modalBody: <SODetail {...props} soPayload={soPayload} />,
      width: "100%",
      hideFooter: true,
      wrapClassName: "modal-custom-detail",
    });
  };
  const showItems = (data) => {

   
  props.pushModalToStack({
    title: "Products",
    modalBody: (
      <ul>
{
  (data||[]).map((val)=>{
    return(
      <li key={val}>{val?.inventoryItemName}</li>
    )
  })
}
      </ul>
    ),
    width: 1000,
    hideFooter: true,
    keyboard: true,
    wrapClassName: "modal-custom-detail",
  });
};
  const actionMenu = (item) => {
    return (
      <Menu className="row-action-dropdown">
        <Menu.Item key="0" value="title">
          Actions
        </Menu.Item>
        <Menu.Divider />
        {permissionsData.create ? (
          <Menu.Item key="1" value="create" onClick={()=>{
            props.updateDrawerState({
                unBoxingDrawerVisible: true,
                data: item
            })
          }}>
            {/* <i className="fa fa-pencil-square-o" /> */}
            <i className={ICONS.EDIT} />
            Create
          </Menu.Item>
        ) : (
          <Fragment />
        )}
        {permissionsData.update ? (
          <Menu.Item key="1" value="edit" onClick={()=>{
            props.updateDrawerState({
                unBoxingDrawerVisible: true,
                data: item
            })
          }}>
            {/* <i className="fa fa-pencil-square-o" /> */}
            <i className={ICONS.EDIT} />
            Edit
          </Menu.Item>
        ) : (
          <Fragment />
        )}
           {
             permissionsData?.delete ? <Menu.Item
             key="3"
             value="Delete"
             onClick={()=>{
              const payload ={
                relationshipId: props.companyInfo.relationshipId,
                unboxingId: item?.unboxingId
              }
              props.deleteUnboxingItem(payload,props)
            }}
           >
             <i className={ICONS.DELETE} />
            Delete
           </Menu.Item>: ""
           }
         
      </Menu>
    );
  };
  return (
    <>
      <div className="view-container-actions">
        <Skeleton loading={props.headerLoading} paragraph={false} rows={1} active>
          <div className="left-actions">
            <div className="table-heading">
              Unboxing Videos List
            </div>

            <>
              <div className="vertical-sep" />
              <div>{props.unBoxingVideoCount || 0}</div>
            </>

          </div>
          <div className="right-actions">

          </div>
        </Skeleton>
      </div>
      <div className="table-container">
        <Skeleton loading={props.listLoading} paragraph={{ rows: 15 }} active>
          <table>
            <thead>
              <tr>
                <th>S.No.</th>
                <th>SO #</th>
                <th>Customer Name</th>
                <th>Product Name</th>
                <th>Date Created</th>
                <th>Published</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {(unBoxingVideoList[pageNumber || 1] || []).length
                ? unBoxingVideoList[pageNumber || 1].map((item, i) => {
                  const image = getAWSFileURL(item?.image, props.companyInfo.bucketName, item?.folderName)
                  
                  return (
                    <tr>
                      <td> {(pageNumber - 1) * props.pageSize + (i + 1)}</td>
                      <td  className='one-line-view table-link-text cursor-pointer'
                            onClick={() => {
                              openSoDetails(item);
                            }}>{item.soNumber ||<div className="empty-data"></div>}</td>
                      <td>{item.customerName||<div className="empty-data"></div>}</td>
                      <td>{(item?.itemList||[])?.length ? ((item?.itemList||[])[0])?.inventoryItemName: ""}{(item?.itemList||[])?.length ? (item?.itemList||[])?.length>1 ? <span onClick={()=>showItems(item?.itemList)}>more</span>:"" :<div className="empty-data"></div>}
                   
                   </td>
                      <td>{item.dateCreated ? getMomentDateForUIReadOnly({ date: new Date(item.dateCreated), format }) : ''}</td>
                     
                      <td>{item?.isActive ? "Yes" : "No"}</td>
                      <td className="text-center">
                          <Dropdown
                            overlay={actionMenu(item)}
                            trigger={["click"]}
                          >
                            <i className={ICONS.MORE} />
                          </Dropdown>
                        </td>

                    </tr>

                  )
                }) :
                <tr key="empty-data-box">
                  <td colSpan={"100%"} style={{ borderBottom: "none" }}>
                    <Empty />
                  </td>
                </tr>
              }
            </tbody>
          </table>
        </Skeleton>
      </div>

      <div className="footer">
        <div className="f-left"></div>
        <div className="f-right">
          <Pagination
            current={props.pageNumber || 1}
            size="small"
            total={props.publishCount}
            showTotal={showTotal}
            defaultPageSize={props.pageSize || 100}
            showSizeChanger
            pageSizeOptions={[10, 25, 50, 100, 200]}
            onChange={(pageNumber, pageSize) => {
              loadPageData(pageNumber, pageSize);
              props.updateState({ pageSize: pageSize, pageNumber: pageNumber });
            }}
            onShowSizeChange={(pageNumber, pageSize) => {
              handlePageSizeChange(pageNumber || 1, pageSize, props);
            }}
          />
        </div>
      </div>

    </>
  )
}
export default injectIntl(UnboxingVideoListing);